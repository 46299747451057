import Paper from '@mui/material/Paper';
import { Grid, Box, Icon} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ThumbUp, Flag, Reply, ModeEdit, ArrowBackIosNew } from '@mui/icons-material';
import { Divider } from '@mui/material'


function CommentsEditTab() {

    return (
      <div style={{ padding: 14 }} className="App">
        <h1>Comments</h1>
        <Paper style={{ padding: "20px" }}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <h4 style={{ margin: 0, textAlign: "left" }}>Michel Michel</h4>
              <p style={{ textAlign: "left" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              </p>
              <p style={{ textAlign: "left", color: "gray", marginBottom: "5px" }}>
                posted 1 minute ago
              </p>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                <IconButton>
                  <ThumbUp style={{ fontSize: '23' }}/>
                </IconButton>
                <span style={{ fontSize: '23' }}>7</span>
                <IconButton>
                  <Flag style={{ fontSize: '23' }}/>
                </IconButton>
                <IconButton>
                  <Reply style={{ fontSize: '23' }}/>
                </IconButton>
              </div>
            </Grid>
            <Divider variant="fullWidth" style={{ margin: "20px 0" }} />
            <Grid item>
              <h4 style={{ margin: 0, textAlign: "left" }}>Michel Michel</h4>
              <p style={{ textAlign: "left" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                luctus ut est sed faucibus. Duis bibendum ac ex vehicula laoreet.
                Suspendisse congue vulputate lobortis.
              </p>
              <p style={{ textAlign: "left", color: "gray", marginBottom: "5px" }}>
                posted 1 minute ago
              </p>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                <IconButton>
                  <ThumbUp style={{ fontSize: '23' }}/>
                </IconButton>
                <span style={{ fontSize: '23' }}>7</span>
                <IconButton>
                  <Flag style={{ fontSize: '23' }}/>
                </IconButton>
                <IconButton>
                  <Reply style={{ fontSize: '23' }}/>
                </IconButton>
              </div>
            </Grid>
            <Divider variant="fullWidth" style={{ margin: "20px 0" }} />
            <Grid item>
              <h4 style={{ margin: 0, textAlign: "left" }}>Michel Michel</h4>
              <p style={{ textAlign: "left" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                luctus ut est sed faucibus. Duis bibendum ac ex vehicula laoreet.
                Suspendisse congue vulputate lobortis.
              </p>
              <p style={{ textAlign: "left", color: "gray", marginBottom: "5px" }}>
                posted 1 minute ago
              </p>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                <IconButton>
                  <ThumbUp style={{ fontSize: '23' }}/>
                </IconButton>
                <span style={{ fontSize: '23' }}>7</span>
                <IconButton>
                  <Flag style={{ fontSize: '23' }}/>
                </IconButton>
                <IconButton>
                  <Reply style={{ fontSize: '23' }}/>
                </IconButton>
              </div>
            </Grid>
            <Divider variant="fullWidth" style={{ margin: "20px 0" }} />
          </Grid>
        </Paper>
      </div>
    );
  };
    
export default CommentsEditTab;  