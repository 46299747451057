import React from "react";
import {Grid, Paper} from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

function Matrix({usefulness}) {

    const labels = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));


    const colors = [
        '#FFFFFF', // White
        '#FFE4E4', 
        '#FFC6C6',
        '#FFACAC',
        '#FF8F8F', //44
        '#FF7171',
        '#FF5454',
        '#FF3838',
        '#FF1D1D',
        '#FF0000', //Red
        '#D0D0D0',

      ];

      const chooseColor = (useful) => {
        if(useful == -1) {
            return colors[10]
        }

        else if(useful == 0) {
            return colors[0]
        }

        else {
            return colors[Math.floor(useful + 0.5)]
        }
      }


    return (
        <TableContainer>
        <Table >
          <TableHead>
            <TableRow >
              <TableCell></TableCell>
              {usefulness.map((_, index) => (
                <TableCell key={index} align="center">
                  {labels[index]}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {usefulness.map((row, rowIndex) => (
              <TableRow key={rowIndex} >
                <TableCell component="th" scope="row" style={{ borderCollapse: "collapse" }}>
                  {labels[rowIndex]}
                </TableCell>
                {row.map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    style={{
                      backgroundColor: chooseColor(cell),
                      border: "2px solid",
                      borderColor: "black",
                    }}
                  ></TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  
  
}

export default Matrix;