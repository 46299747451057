import NavBar from "../../components/NavBar/NavBar"
import { TextField } from "@mui/material";
import Button from '@mui/material/Button';
import React from 'react';
import axios from "axios";
import {useNavigate } from 'react-router-dom';


function RegisterPage() {

   const [firstName, setFirstName] = React.useState("")
   const [lastName, setLastName] = React.useState("")
   const [email, setEmail] = React.useState("")
   const [phoneNumber, setPhoneNumber] = React.useState("")
   const [jobTitle, setJobTitle] = React.useState("")
   const [training, setTraining] = React.useState("")
   const [password, setPassword] = React.useState("")

   const navigate = useNavigate();

   const handleFirstName = (event) => {
    setFirstName(event.target.value)
   }

   const handleLastName = (event) => {
    setLastName(event.target.value)
   }

   const handleEmail = (event) => {
    setEmail(event.target.value)
   }

   const handlePhoneNumber = (event) => {
    setPhoneNumber(event.target.value)
   }

   const handleJobTitle = (event) => {
    setJobTitle(event.target.value)
   }

   const handleTraining = (event) => {
    setTraining(event.target.value)
   }

   const handlePassword = (event) => {
    setPassword(event.target.value)
   }

   const createUser = async () => {

    if(email == "" || password == "" || firstName == "" || lastName == "") {
      alert('Email, password, first name, and last name cannot be null')
      return
    }


    const payload = {
        firstName: firstName, 
        lastName: lastName, 
        training: training,
        job_title: jobTitle, 
        phone_number: phoneNumber,
        email: email, 
        password: password
    }
    await axios.post("https://api.clinicalphenomics.org/register", payload)

    navigate('/login')


   }







    return (
<div>
  <NavBar />

  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',  height: '90vh', justifyContent: 'center'  }}>
  <h1 style={{ fontSize: '2rem', marginBottom: '20px',  }}>Registration</h1>



  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <label htmlFor="firstName" style={{ width: '200px', marginRight: '10px' }}> First name:</label>
    <TextField id="firstName" value={firstName} onChange={handleFirstName}   />
  </div>

  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <label htmlFor="lastName" style={{ width: '200px', marginRight: '10px' }}> Last name:</label>
    <TextField id="lastName" value={lastName} onChange={handleLastName} />
  </div>

  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <label htmlFor="email" style={{ width: '200px', marginRight: '10px' }}> Email:</label>
    <TextField id="email" value={email} onChange={handleEmail}/>
  </div>

  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <label htmlFor="phonenumber" style={{ width: '200px', marginRight: '10px' }}> Phone Number:</label>
    <TextField id="phonenumber" value={phoneNumber} onChange={handlePhoneNumber} />
  </div>

  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <label htmlFor="jobtitle" style={{ width: '200px', marginRight: '10px' }}> Job Title:</label>
    <TextField id="jobtitle" value={jobTitle} onChange={handleJobTitle} />
  </div>

  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <label htmlFor="training" style={{ width: '200px', marginRight: '10px' }}> Please rate your level of training from 1-9:</label>
    <TextField id="training" value={training} onChange={handleTraining}/>
  </div>

  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <label htmlFor="password" style={{ width: '200px', marginRight: '10px' }}> Password</label>
    <TextField type="password" id="password"value={password} onChange={handlePassword} />
  </div>


  <Button variant="contained" onClick={createUser} > Register </ Button> 

  </div>


</div>

    )

}

export default RegisterPage;