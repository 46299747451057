import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Popover from '@mui/material/Popover';
import axios from "axios";


function KnowledgeEditTab({type, entity, updated, setUpdated}) {

  const [numRatings, setNumRatings] = React.useState(0);
  const [userRating, setUserRating] = React.useState({});
  const [valueEntered, setValueEntered] = React.useState(-1);
  const validValues = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

  const handleKnowledgeChange = (event) => {
    if(event.target.value == "") {
      setValueEntered(-1)
    } else {
    setValueEntered(event.target.value)
    }
   }

   const handleRateKnowledge = async () => {
    if(Object.keys(userRating) == 0) {
      if(valueEntered == -1) {
        return
      }
      else {

        var newType
        if(type == "disease") {
          newType = "Disease"
        } else if(type == "finding") {
          newType = "Finding"
        }

        if(!validValues.includes(String(valueEntered))) {
          alert("Your knowledge rating must be an integer from 1 to 9")
          return
        }

        console.log(valueEntered)
        const payload = {type: newType, knowledge: valueEntered, selected: false, type_id: entity.id }
        const result = await axios.post("https://api.clinicalphenomics.org/raiting/DorF", payload, {
          headers: {
            Authorization: `Bearer ${document.cookie}`
          }
        })

        console.log(result)
      }
    }
    else {
      if(valueEntered == -1 && !userRating.selected) {
        const a = await axios.delete(`https://api.clinicalphenomics.org/raiting/DorF/${userRating.id}`, {
          headers: {
            Authorization: `Bearer ${document.cookie}`
          }
        })
        console.log(a)
        setUpdated(!updated)
      }
      else {
        const payload = {
          knowledge: valueEntered == -1 ? null : valueEntered
        }

        if(!validValues.includes(String(valueEntered))) {
          alert("Your knowledge rating must be an integer from 1 to 9")
          return
        }

        console.log(document.cookie)

        await axios.put(`https://api.clinicalphenomics.org/raiting/type/DorF/${userRating.id}`, payload, {
          headers: {
            Authorization: `Bearer ${document.cookie}`
          }
        })
      }
    }
    
    setUpdated(!updated)
   }

  
  React.useEffect(() =>{
    const fetchEntities = async () => {

      const newEntity = await axios.get(`https://api.clinicalphenomics.org/raiting/DorF/stats/${type}/id/${entity.id}`)
      setNumRatings(newEntity.data)
    }

    fetchEntities()

  }, [entity, updated])

  React.useEffect(() => {
    const payload = {
      type,
      type_id: entity.id
    }

     const getUserRating = async () => {
      if(document.cookie != "") {
        const userRating = await axios.post('https://api.clinicalphenomics.org/raiting/DorF/getByUser', payload,  {
          headers: {
            Authorization: `Bearer ${document.cookie}`
          }
        })

        let currUserRating = userRating.data.length > 0 ? userRating.data[0] : {}
        setUserRating(currUserRating)
        setValueEntered(currUserRating.knowledge ? currUserRating.knowledge : -1)
        }
        else{
          return;
        }
     }

    getUserRating()
    }, [updated])
    function createData(name, statistic) {
      return { name, statistic};
    }
  
     const rows = [
        createData('Average Knowledge', numRatings != 0 ?  entity.knowledge: ""),
        createData('Number of Ratings', numRatings),
      ];
    
  
    

    
   const handleMouseEnter = (event) => {
    setShowPopup(event.currentTarget);
   };
  
   const handleMouseLeave = () => {
     setShowPopup(null);
   };

   

   const [showPopup, setShowPopup] = React.useState(null);
   const popupOpen = Boolean(showPopup)
  
  
  
    return (
      <div>
      <h2> Rate Expertise Level of Disease </h2>
      <TableContainer component={Paper}>
       <Table sx={{ minWidth: 650 }} aria-label="simple table">
  
       <TableBody>
       {rows.map((row) => (
          <TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.statistic}</TableCell>
  
          </TableRow>
        ))}
  
       </TableBody>
  
       </Table>
        </TableContainer>
  
  
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>

    {document.cookie == "" && <p> You need to log in to add a knowledge rating.</p>}
    {document.cookie != "" && 
    <>
    <p style={{ marginRight: '10px' }}>
      Please rate the level of expertise from 1-9 at which one is expected to be familiar with this disease:
    </p>
    <TextField
      id="outlined-basic"
      multiline
      value={valueEntered != -1 ? valueEntered: ""}
      onChange = {handleKnowledgeChange}
      inputProps={{
        style: { minHeight: '20px', maxHeight: '20px', minWidth: '20px', maxWidth: '20px', textAlign: 'left' },
      }}
      variant="outlined"
    />
    <Button variant="contained" style={{marginLeft: '20px'}} onClick={handleRateKnowledge}> Submit </Button>
    </>
     }
  
  </div>
  
  <div style={{ position: 'absolute', top: '1vh', right: '0.25vw' }}>
  
  
  <HelpOutlineIcon onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
  
  <Popover
  id="mouse-over-popover"
  sx={{
    pointerEvents: 'none',
  }}
  open={popupOpen}
  anchorEl={showPopup}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
  onClose={handleMouseLeave}
  disableRestoreFocus
  >
  <div style={{ padding: '8px' }}>
  
  <h2>  Experience Levels</h2>
  <p> 1 - Undergrad Student</p>
  <p> 2 </p>
  <p> 3 </p>
  <p> 4 </p>
  <p> 5 </p>
  <p> 6 </p>
  <p> 7 </p>
  <p> 8 </p>
  <p> 9 - Expert </p>
  
  </div>
  
  </Popover>
  
  
  </div>
  
  
  
  
      </div>
    )
  }

  export default KnowledgeEditTab;