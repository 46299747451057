import React from 'react';
import BasicAccordion from "./components/BasicAccordion/BasicAccordion"
import BoxSx from "./components/BoxSx/BoxSx"
import ExpirienceModal from "./components/ExpirienceModal/ExpirienceModal"
import NavBar from "../../components/NavBar/NavBar"
import NestedModal from "./components/NestedModal/NestedModal"
import SearchBar from "./components/SearchBar/SearchBar"
import axios from 'axios';
import filter from 'jade/lib/filters';

function SelectPage({selectedEntity, setSelectedEntity, filterSelect, setFilterSelect}) {

  const [onUpdate, setOnUpdate] = React.useState(false)
  const [onUpdateV2, setOnUpdateV2] = React.useState(false)


  return (    
  <div> 
  <NavBar />  

  <div style={{ position: 'absolute', top: '23vh', left: '50vw',  transform: 'translate(-50%, -50%)'  }}>
    <p> Welcome to Clinical Phenomics! Please select diseases or findings below to view their assoicated references, images, comments, and additional statistics. You could view info about diseases individually through the select tab and view statistics, references, and images on disease/finding pairs in the Diseases and Findings tabs. Also please create an account to help contribute to our search engine!  
    </p>

  </div>

  <div style={{ position: 'absolute', top: '40vh', left: '9vw',  transform: 'translate(-50%, -50%)'  }}>
  <NestedModal type="disease" onUpdate={onUpdate} setOnUpdate={setOnUpdate}/>

  </div>

  <div style={{ position: 'absolute', top: '40vh', left: '19vw',  transform: 'translate(-50%, -50%)'  }}>
  <SearchBar label="Search for a Disease" type="disease" selected={selectedEntity} setSelected={setSelectedEntity} filtered={filterSelect} setFiltered={setFilterSelect}/>
  </div>

  <div style={{ position: 'absolute', top: '30vh', left: '78vw',  transform: 'translate(-50%, -50%)'  }}>
    <h2> Search finding by name</h2>
  </div>

  <div style={{ position: 'absolute', top: '30vh', left: '13vw',  transform: 'translate(-50%, -50%)'  }}>
    <h2> Search disease by name</h2>
  </div>

  <div style={{ position: 'absolute', top: '49vh', left: '12vw',  transform: 'translate(-50%, -50%)'  }}>
    <h2> Search by hierarchy</h2>
  </div>

  <div style={{ position: 'absolute', top: '49vh', left: '77vw',  transform: 'translate(-50%, -50%)'  }}>
    <h2> Search by hierarchy</h2>
  </div>


  <div style={{ position: 'absolute', top: '49vh', left: '50vw',  transform: 'translate(-50%, -50%)'  }}>
    <h2> View selected diseases/findings</h2>
  </div>

  <div style={{ position: 'absolute', top: '40vh', left: '74vw',  transform: 'translate(-50%, -50%)'  }}>
  <NestedModal type="finding"   onUpdate={onUpdate} setOnUpdate={setOnUpdate}/>
  </div>

  <div style={{ position: 'absolute', top: '40vh', left: '50vw',  transform: 'translate(-50%, -50%)'  }}>
  <ExpirienceModal selected={selectedEntity} setFilterSelect={setFilterSelect}/>
  </div>
  <div style={{ position: 'absolute', top: '40vh', left: '85vw',  transform: 'translate(-50%, -50%)'  }}>
  <SearchBar label="Search for a Finding"  type="finding" selected={selectedEntity} setSelected={setSelectedEntity} filtered={filterSelect} setFiltered={setFilterSelect}/>
  </div>

  <div style={{ position: 'absolute', top: '73vh', left: '50vw',  transform: 'translate(-50%, -50%)'  }}>
  <BoxSx allSelected={selectedEntity} setAllSelected={setSelectedEntity} selected={filterSelect} setSelected={setFilterSelect} onUpdate={onUpdate} setOnUpdate={setOnUpdate}/>
  </div>
 
      <div style={{ position: 'absolute', top: '53vh', left: '7vw' }}>
            < BasicAccordion width='20vw' widthBox='22vw' filSelected={filterSelect} setFilSelect={setFilterSelect} selected={selectedEntity} setSelected={setSelectedEntity} type="disease" onUpdate={onUpdate}/>
      </div>

       <div style={{ position: 'absolute', top: '53vh', left: '72vw'}}>
           < BasicAccordion width='20vw' widthBox='22vw' filSelected={filterSelect} setFilSelect={setFilterSelect} selected={selectedEntity} setSelected={setSelectedEntity} type="finding" onUpdate={onUpdate}/>
       </div>


</div>

  )
}



export default SelectPage;
