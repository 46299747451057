import { Box, Typography, Chip } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import TextBox from "../../../pages/select/components/TextBox/TextBox";
import OptButton from "../../../pages/select/components/OptButton/OptButton";
import CancelIcon from '@mui/icons-material/Cancel';
import React from "react";
import axios from "axios";
import Button from '@mui/material/Button';

function UpdateImage({type, entity, setEditMode,setIndex}) {

    const commonStylesTwo = {
        bgcolor: 'background.paper',
        m: '6px',
        borderColor: 'text.primary',
        width: '250px',
        position: 'relative',
        height: '350px',
        marginLeft: '100px'
      };

      const [title, setTitle] = React.useState('');
      const [description, setDescription] = React.useState('');
      const [bodySite, setBodySite] = React.useState('');
      const [comment, setComment] = React.useState('');
      const [imagesToAdd, setImagesToAdd] = React.useState([]);
      const [copyOrig, setCopyOrig] = React.useState([])
      const [addMode, setAddMode] = React.useState(true)
      const [selectedImage, setSelectedImage] = React.useState(-1);

    React.useEffect(() => {
        const getImages = async () => {
            const images = await axios.get(`https://api.clinicalphenomics.org/image/user/all/${type}/id/${entity.id}`,
            {
                headers: {
                  Authorization: `Bearer ${document.cookie}`,
                },
              }
            )

            const allImages = images.data



            const copy = [...allImages]

            setImagesToAdd(allImages)
            setCopyOrig(copy)
        }



        getImages()
    }, [])

    const handleUpdate = async () => {

        var updatedOrNew = imagesToAdd.filter((image) => !copyOrig.includes(image) )
        var updated = updatedOrNew.filter((image) => image.hasOwnProperty("id"))
        var newImage = updatedOrNew.filter((image) => !image.hasOwnProperty("id"))
        var deletedOrUpdated = copyOrig.filter((image) => !imagesToAdd.includes(image))
        var deleted = deletedOrUpdated.filter((image) => !updated.some(img => img.id === image.id))

        console.log(updated);
        console.log(deleted);

        for(const image of deleted) {
          await axios.delete(`https://api.clinicalphenomics.org/image/${image.id}`, {
            headers: {
              Authorization: `Bearer ${document.cookie}`
            }
          })
        }

        
        for(const image of updated) {
            let payload = {...image}
            delete payload["id"]
            console.log(payload)


            await axios.put(`https://api.clinicalphenomics.org/image/${image.id}`, payload, 
            {
                headers: {
                  Authorization: `Bearer ${document.cookie}`,
                },
              })

  
        }

        for(const image of newImage) {
           const [fileNameWithoutExtension, fileExtension] = image.image.name.split('.');

            let payload = {...image}
            let newComment = payload["comment"]
            delete payload["comment"]
            if(type == "finding"){
                payload["type"] = "Finding"
            } else if(type == "disease") {
                payload["type"] = "Disease"
            }
            else if(type == "Disease Finding") {
              payload["type"] = "Disease Finding"
            }

            payload["id"] = entity.id
            payload["extension"] = fileExtension

            

            const newImage = await axios.post(`https://api.clinicalphenomics.org/image`, payload, 
            {
                headers: {
                  Authorization: `Bearer ${document.cookie}`,
                },
              })

      const formData = new FormData();
      const modifiedFileName = `${newImage.data.id}.${fileExtension}`;

      formData.append('file', image.image); 
      formData.append('filename', modifiedFileName ); 


      await axios.post("https://api.clinicalphenomics.org/image/upload", 

      formData, {
        headers: {
          Authorization: `Bearer ${document.cookie}`,
                          'Content-Type': 'multipart/form-data'
        }
      })

              
      await axios.post( `https://api.clinicalphenomics.org/comment`,
      {body: newComment, type: "Image", type_id: newImage.data.id},
      {
      headers: {
            Authorization: `Bearer ${document.cookie}`
       }})
      }

        setIndex(0)
        setEditMode(false)
        






        



      
    }

    
    const [newFileImage, setNewFileImage] = React.useState(null)
    const handleImageClick = () => {
      if(newFileImage) {
        setNewFileImage(null)
      }

    }

    const handleFileSelect = (event) => {
      const file = event.target.files[0]
      if(file) {
        setNewFileImage(file)
      }
    }


    const handleUpdateImage = () => {

        if(selectedImage == -1) {
            return
        }

        const newArray = [...imagesToAdd]

        if(newArray.length != 0 && newArray[selectedImage].hasOwnProperty("id")) {
            newArray[selectedImage] = {id: newArray[selectedImage].id, title, description, body_site: bodySite}
        }
        else {
            newArray[selectedImage] = {title, description, body_site: bodySite, comment}
        }


        setImagesToAdd(newArray)


    }

    const handleMode = () => {

        if(imagesToAdd.length == 0) {
            setSelectedImage(-1)
            setAddMode(true)
            return;
        }
        
        let newMode = !addMode
        
        if(newMode) {
            setTitle("")
            setDescription("")
            setBodySite("")
            setComment("")
            setSelectedImage(-1)
        }

        else {
            setSelectedImage(0)
            setTitle(imagesToAdd[0].title)
            setDescription(imagesToAdd[0].description)
            setBodySite(imagesToAdd[0].body_site)
        }

        setAddMode(!addMode)

        
    }

    



      const handleTitle = (event) => {
        setTitle(event.target.value)
      }

      const handleDescription = (event) => {
        setDescription(event.target.value)
      }

      const handleComment = (event) => {
        setComment(event.target.value)
      }

      const handleBodySite = (event) => {
        setBodySite(event.target.value)
      }

    const handleAddImage = (event) => {
  
        if(newFileImage == null) {
          alert("You must add an image")
          return;
        }

        setImagesToAdd([...imagesToAdd, {title, description, body_site: bodySite, comment, image: newFileImage} ])
        setTitle("");
        setDescription("");
        setBodySite("")
        setComment("");
        setNewFileImage(null);

      }

    const handleImageSelect = (event, index, title, description, bodySite, comment, image) => {
        setTitle(title)
        setDescription(description)
        setBodySite(bodySite)
        if(comment) {
        setComment(comment)
        }
        setSelectedImage(index)
        setNewFileImage(image)

    }

    const handleImageDelete = (event, id, title, description, bodySite, comment) => {
        var newImages = imagesToAdd.filter(item => 
                        item.title != title || 
                        item.description != description || 
                        item.body_site != bodySite ||
                        item.id != id)
        

          setImagesToAdd(newImages) 
          setSelectedImage(-1)
          handleMode()
      }



  
      
    


    

      
    return (
        <div>
        <Box sx={{overflowY: 'auto'}}>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h2 id="parent-modal-title">Add/Modify Images</h2>
        <Button variant="contained" onClick={handleMode} style={{ minWidth: '40px',  maxWidth: '40px', height: '40px', marginLeft: '30px', color: "white",  backgroundColor: addMode ? '#1565c0':'#42a5f5'  }} > + </Button>
        </div>


        <div style={{display: 'flex', justifyContent: 'left'}}>
  
        <div style={{ position: 'relative', top: '0px', left: '0px' }}>
            <Typography style={{ marginBottom: '5px', textAlign: 'left' }}> Title: </Typography>
            <TextBox height="18" width="300" value={title} handleValueChange={handleTitle} />
  
            <Typography style={{ marginTop: '5px', textAlign: 'left'  }}> Description: </Typography>
            <TextBox height="72" width="300" value={description} handleValueChange={handleDescription}/>
  
            <Typography style={{ marginTop: '5px',  textAlign: 'left'  }}> Body Site: </Typography>
            <TextBox height="18" width="300" value={bodySite} handleValueChange={handleBodySite}/>



            { (selectedImage == -1 || imagesToAdd.length == 0 || !imagesToAdd[selectedImage].hasOwnProperty("id")) &&
            <>
            <div>
            <Typography style={{ marginTop: '5px',  textAlign: 'left'  }}> Comment: </Typography>
            <TextBox height="18" width="300" value={comment} handleValueChange={handleComment}/>
            </div>

            {(newFileImage != null && (addMode || imagesToAdd.length == 0 || !imagesToAdd[selectedImage].hasOwnProperty("id"))) && <Typography style={{ marginTop: '5px', textAlign: 'left' }}> File Uploaded: {newFileImage.name} </Typography>}


            </>
            }
            

            <div>
            { (selectedImage == -1 || imagesToAdd.length == 0 || !imagesToAdd[selectedImage].hasOwnProperty("id") || addMode) &&
            <Button component="label" onClick={handleImageClick} style={{marginTop: 10}} variant="contained" > Browse Image 
            <input
            type="file"
            ref={newFileImage}
            style={{ display: 'none' }}
            accept="image/*"
            hidden
            onChange={handleFileSelect}
          />
          </Button>           
          
          }

            {addMode && <OptButton  label="Add Image" leftmarg={65} marg={10} onClick={handleAddImage}/>}
            {!addMode && <OptButton  label="Update Image" leftmarg={65} marg={10} onClick={handleUpdateImage}/>}


  
            </div>
        </div>
        <div style={{ width: '15px' }}>
          </div>
  
  
        <div> 
  
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ ...commonStylesTwo, border: 1 }}>

          {imagesToAdd.map((image,index) => (
        <Chip sx={{marginTop: '10px', width: '100%' , justifyContent: 'space-between', backgroundColor: selectedImage === index ? '#808080' : undefined}}  onDelete={(event) => handleImageDelete(event, image.id, image.title, image.description, image.body_site)}  deleteIcon={<CancelIcon />} key={uuidv4()} label={image.title} onClick={(event) => handleImageSelect(event, index, image.title, image.description, image.body_site, image.comment, image.image)} />

          ))}
          </Box>
             </Box>
             <OptButton label = "Save" onClick = {handleUpdate} leftmarg={250} marg={20} />

  
  
        </div>
        </div>
        </Box>
        </div>
        )
}

export default UpdateImage;