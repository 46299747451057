import { Grid, Box, Icon} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function DFindingTabs({activeTab, onTabChange}) {

    const handleChange = (event, newValue) => {
      onTabChange(newValue);
    };

      
    return (
      <Box sx={{ width: '100%' }}>

        <Tabs
          value={activeTab}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >

          <Tab value="tab1" label="Rating" />
          <Tab value="tab2" label="Prevalence" />
          <Tab value="tab3" label="Images" />
        </Tabs>
        </Box>
        
    );
  }

  export default DFindingTabs;