import CommentIcon from '@mui/icons-material/Comment';
import Modal from '@mui/material/Modal';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { Grid, Box, Icon} from '@mui/material';
import { ThumbUp, Flag, Reply, ModeEdit, ArrowBackIosNew } from '@mui/icons-material';
import { Divider } from '@mui/material'
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Container, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import axios from "axios";

function SeeFlagModal ({type, id, flag_type, open, setOpen}) {

    const [flags, setFlags] = React.useState([]);

    const style = {
        position: 'absolute',
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: "24vh",
        pt: "2vh",
        px: "4vh",
        pb: "3vh",
        maxHeight: '90vh',
        overflowY: 'auto',
      };


    React.useEffect(() => {
        const getFlags = async () => {

            const flag = await axios.get(`https://api.clinicalphenomics.org/flag/getAllFlags/${type}/id/${id}/flagType/${flag_type}`)
            setFlags(flag.data)
        }

        getFlags()
    }, [])

    const handleClose = () =>{
        setOpen("")
    }
 
    return (
        <div> 

        <Modal open={open != ""}              
               onClose={handleClose}
               aria-labelledby="parent-modal-title"
               aria-describedby="parent-modal-description">
          <Box sx={{ ...style, overflowY: 'auto', textAlign:'center'}}>
          <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
              <CloseIcon  onClick={handleClose}/>
           </div>

           <div>
            <h1>Flags</h1>
            <Paper style={{ padding: "20px" }}>
            <Grid container direction="column" spacing={1}> 
            {flags.map(flag => ( 
 
            <div>
                <Grid item>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>

                     <h4 style={{ margin: 0, textAlign: "left" }}>{flag.User && flag.User.first_name} {flag.User && flag.User.last_name}</h4>

                    </div>
                    <>
                    <p style={{ textAlign: "left" }}>{flag.description} </p>
            </>

            </Grid>
            <Divider variant="fullWidth"  style={{ margin: "10px 0" }} />
            </div>
           ))} 
            </Grid>
            </Paper>
        </div>

        
  

        </Box>
        </Modal>
    </div>
    )

}

export default SeeFlagModal;