
import NavBar from "../../components/NavBar/NavBar";
import React from 'react';
import axios from "axios";
import Box from '@mui/material/Box';
import { Card, CardContent, Typography } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Container, TextField } from "@mui/material";
import Button from '@mui/material/Button';


function ProfilePage() {

    const [user, setUser] = React.useState({})
    const [editMode, setEditMode] = React.useState(false)
    const [newTraining, setNewTraining] = React.useState("")
    const [phone,setPhone] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [job, setJob] = React.useState("")

    


    React.useEffect(() => {

        const fetchEntity = async () => {
          if(editMode) {
            return;
          }
        const curr_user = await axios.get(`https://api.clinicalphenomics.org/currUserInfo`,
        {
          headers: {
            Authorization: `Bearer ${document.cookie}`,
          },
        })

        setUser(curr_user.data)
        setNewTraining(curr_user.data.traning)
        setPhone(curr_user.data.phone_number)
        setEmail(curr_user.data.email)
        setJob(curr_user.data.job_title)
    }

    fetchEntity();

    }, [editMode])


    const switchToEdit = () => {
      setEditMode(true)
    }

    const handleTrainChange = (event) =>{
      setNewTraining(event.target.value)
    }

    const handlePhoneChange = (event) => {
      setPhone(event.target.value)
    }

    const handleEmailChange = (event) =>{
      setEmail(event.target.value)
    }

    const handleJobChange = (event) => {
      setJob(event.target.value)
    }

    const updateUser = async () => {

      if(email == "") {
        alert('Email cannot be null')
        return
      }

      const payload = {
        traning: newTraining,
        phone_number: phone, 
        email: email,
        job_title: job
      }


      await axios.put(`https://api.clinicalphenomics.org/${user.id}`, payload, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })

      setEditMode(false)
    }

    return (
        <div>
  <NavBar />

  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',  width: '100vw', height: '10vh', justifyContent: 'center'  }}>
  <h1 style={{ fontSize: '3rem', marginBottom: '20px' }}>{user.first_name} {user.last_name} </h1>
  


  </div>

  <Card>
      <CardContent>
        <Typography variant="h5" style={{textAlign: "center"}}component="div">
          User Information
          <ModeEditIcon onClick={switchToEdit} color="primary" style={{marginLeft: "10px"}} />
        </Typography>
        
        <Typography variant="h6" color="text.secondary" style={{marginBottom: '10px'}}>
          Level of Training: {!editMode && user.traning} {editMode && <TextField value={newTraining} onChange={handleTrainChange} /> }
        </Typography>

        <Typography variant="h6" style={{marginBottom: '10px'}} color="text.secondary">
          Phone Number: {!editMode && user.phone_number} {editMode && <TextField value={phone} onChange={handlePhoneChange} /> }
        </Typography>

        <Typography variant="h6" style={{marginBottom: '10px'}} color="text.secondary">
          Email: {!editMode && user.email} {editMode &&  <TextField value={email} onChange={handleEmailChange}/>}
        </Typography>

        <Typography variant="h6" style={{marginBottom: '10px'}} color="text.secondary">
          Job Title: {!editMode && user.job_title} {editMode && <TextField value={job} onChange={handleJobChange}/>}
        </Typography>

        {editMode && <Button  variant="contained"onClick={updateUser} style={{marginTop: '10px'}}> Update </Button>}



      </CardContent>
    </Card>


  </div>
  
    )
 }

export default ProfilePage;