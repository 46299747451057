import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { Grid, Box, Icon} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ThumbUp, Flag, Reply, ModeEdit, ArrowBackIosNew, OutlinedFlag } from '@mui/icons-material';
import { Container, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';


function AddFlagModal({imageType, imageStyle, type, entity}) {
    const [OReason, setOReason] = React.useState("")
    const [RReason, setRReason] = React.useState("")
    const [GReason, setGReason] = React.useState("")
    const [BReason, setBReason] = React.useState("")
    const [OFlagged, setOFlagged] = React.useState(false);
    const [RFlagged, setRFlagged] = React.useState(false);
    const [GFlagged, setGFlagged] = React.useState(false);
    const [BFlagged, setBFlagged] = React.useState(false);
    
    const [open, setOpen] = React.useState(false);
    const [currentFlag, setCurrentFlag] = React.useState("")
    const [storedFlags, setStoredFlags] = React.useState({})



    const handleOpen = () => {
        if(document.cookie == "") {
            alert("You must be logged in to add a flag");
            return;
        }
        setOpen(true)
    }

    React.useEffect( () => {

      const getFlags = async () => {
    
      if(open) {
        const flags = await axios.get(`https://api.clinicalphenomics.org/flag/getFlagsByUser/${type}/id/${entity.id}`, {
        headers: {
            Authorization: `Bearer ${document.cookie}`
        }})

        console.log(flags.data)


        setRFlagged(Boolean(flags.data[2].id) || false)
        setRReason(flags.data[2].description || "")
        setOFlagged(Boolean(flags.data[3].id) || false)
        setOReason(flags.data[3].description|| "")
        setGFlagged(Boolean(flags.data[1].id) || false)
        setGReason(flags.data[1].description || "")
        setBFlagged(Boolean(flags.data[0].id) || false)
        setBReason(flags.data[0].description || "")

        setStoredFlags(flags.data)



      } 
    }
    
    getFlags()
    }, [open])

    const updateFlags = async () => {
        var payload;

        
        if(RFlagged) {

            if(RReason == "") {
                alert("Question must be given")
                return
            }



            if(!Boolean(storedFlags[2].id)) {
                payload = {type: type, flag_type: "Question", id: entity.id, description: RReason}
                await axios.post("https://api.clinicalphenomics.org/flag", payload, {
                    headers: {
                        Authorization: `Bearer ${document.cookie}`
                    }
                })
            }

            else if(storedFlags[2].description != RReason) {

                const payload = {description: RReason}

                await axios.put(`https://api.clinicalphenomics.org/flag/${storedFlags[2].id}`, payload, {
                    headers: {
                        Authorization: `Bearer ${document.cookie}`
                    }
                })
            }
        } else {
            if(Boolean(storedFlags[2].id)) {
                await axios.delete(`https://api.clinicalphenomics.org/flag/${storedFlags[2].id}`, {
                    headers: {
                        Authorization: `Bearer ${document.cookie}`
                    }
                })
            }
        }

        if(BFlagged) {
            if(!Boolean(storedFlags[0].id)) {
                payload = {type: type, flag_type: "Review", id: entity.id, description: BReason}
                await axios.post("https://api.clinicalphenomics.org/flag", payload, {
                    headers: {
                        Authorization: `Bearer ${document.cookie}`
                    }
                })
            }

            else if(storedFlags[0].description != BReason) {

                const payload = {description: BReason}

                await axios.put(`https://api.clinicalphenomics.org/flag/${storedFlags[0].id}`, payload, {
                    headers: {
                        Authorization: `Bearer ${document.cookie}`
                    }
                })
            }
        } else {
            if(Boolean(storedFlags[0].id)) {
                await axios.delete(`https://api.clinicalphenomics.org/flag/${storedFlags[0].id}`, {
                    headers: {
                        Authorization: `Bearer ${document.cookie}`
                    }
                })
            }
        }

        if(OFlagged) {
            if(OReason == "") {
                alert("Comment must be given")
                return
            }
            if(!Boolean(storedFlags[3].id)) {
                payload = {type: type, flag_type: "Comment", id: entity.id, description: OReason}
                await axios.post("https://api.clinicalphenomics.org/flag", payload, {
                    headers: {
                        Authorization: `Bearer ${document.cookie}`
                    }
                })
            }

            else if(storedFlags[3].description != OReason) {

                const payload = {description: OReason}

                await axios.put(`https://api.clinicalphenomics.org/flag/${storedFlags[3].id}`, payload, {
                    headers: {
                        Authorization: `Bearer ${document.cookie}`
                    }
                })
            }
        } else {
            if(Boolean(storedFlags[3].id)) {
                await axios.delete(`https://api.clinicalphenomics.org/flag/${storedFlags[3].id}`, {
                    headers: {
                        Authorization: `Bearer ${document.cookie}`
                    }
                })
            }
        }

        if(GFlagged) {
            if(!Boolean(storedFlags[1].id)) {
                payload = {type: type, flag_type: "Verify", id: entity.id, description: GReason}
                await axios.post("https://api.clinicalphenomics.org/flag", payload, {
                    headers: {
                        Authorization: `Bearer ${document.cookie}`
                    }
                })
            }

            else if(storedFlags[1].description != GReason) {

                const payload = {description: GReason}

                await axios.put(`https://api.clinicalphenomics.org/flag/${storedFlags[1].id}`, payload, {
                    headers: {
                        Authorization: `Bearer ${document.cookie}`
                    }
                })
            }
        } else {
            if(Boolean(storedFlags[1].id)) {
                await axios.delete(`https://api.clinicalphenomics.org/flag/${storedFlags[1].id}`, {
                    headers: {
                        Authorization: `Bearer ${document.cookie}`
                    }
                })
            }
        }
       
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSelectionChange = (selection) => {
        if(selection == currentFlag) {
            if(selection == "red") {
                setRFlagged(!RFlagged)
            }
            if(selection == "orange") {
                setOFlagged(!OFlagged)
            }
            if(selection == "blue") {
                setBFlagged(!BFlagged)
            }
            if(selection == "green") {
                setGFlagged(!GFlagged)
            }
        }
        setCurrentFlag(selection)
    }

    const handleChangeOReason = (event) => {
        setOReason(event.target.value)
    }

    const handleChangeRReason = (event) => {
        setRReason(event.target.value)
    }

    const handleChangeGReason = (event) => {
        setGReason(event.target.value)
    }

    const handleChangeBReason = (event) => {
        setBReason(event.target.value)
    }


    const style = {
        position: 'absolute',
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: "24vh",
        pt: "2vh",
        px: "4vh",
        pb: "3vh",
        maxHeight: '90vh',
        overflowY: 'auto',
      };

      
    return(
        <div> 


        
     
       {imageType == "plus" && <AddIcon style={imageStyle} onClick={handleOpen}/>}
       {imageType == "flag" && <Flag style={imageStyle} onClick={handleOpen} /> }
        <Modal open={open}              
               onClose={handleClose}
               aria-labelledby="parent-modal-title"
               aria-describedby="parent-modal-description">
          <Box sx={{ ...style, overflowY: 'auto', textAlign:'center'}}>
          <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
              <CloseIcon  onClick={handleClose}/>
           </div>

           <h1> Add Flags </h1>
           <div style={{display: "flex"}}>
            <div> 
            <h3> Question </h3>
            {RFlagged ? (
           <Flag onClick={() => handleSelectionChange('red')} style={{ color: 'red', fontSize: '80px', margin: '20px', border: currentFlag === "red" ? "2px solid black": "" }}  />
            ): (
               <OutlinedFlag onClick={() => handleSelectionChange('red')} style={{ color: 'red', fontSize: '80px', margin: '20px', border: currentFlag === "red" ? "2px solid black": "" }}  />
            )}
            </div>
            <div> 
            <h3> Comment </h3>
            {OFlagged ? (
           <Flag onClick={() => handleSelectionChange('orange')} style={{ color: 'orange', fontSize: '80px', margin: '20px', border: currentFlag === "orange" ? "2px solid black": ""  }}  />
            ): (
                <OutlinedFlag onClick={() => handleSelectionChange('orange')} style={{ color: 'orange', fontSize: '80px', margin: '20px', border: currentFlag === "orange" ? "2px solid black": "" }}  />
            )}
            </div> 

            <div>
            <h3> Verify </h3>
            {GFlagged ? (
               <Flag onClick={() => handleSelectionChange('green')} style={{ color: 'green', fontSize: '80px', margin: '20px', border: currentFlag === "green" ? "2px solid black": ""  }}  />
            ): (
                <OutlinedFlag onClick={() => handleSelectionChange('green')} style={{ color: 'green', fontSize: '80px', margin: '20px', border: currentFlag === "green" ? "2px solid black": ""  }}  />
            )}

           </div>

           <div> 

           <h3> Review </h3>


            {BFlagged ? (
                <Flag onClick={() => handleSelectionChange('blue')}  style={{ color: 'blue', fontSize: '80px', margin: '20px', border: currentFlag === "blue" ? "2px solid black": ""  }}  />
            ): (
                <OutlinedFlag onClick={() => handleSelectionChange('blue')}  style={{ color: 'blue', fontSize: '80px', margin: '20px', border: currentFlag === "blue" ? "2px solid black": ""  }}  />
            )}
            </div>

           </div>

           <div >{currentFlag != "" &&
            <typography> Reason: </typography>
            }
          </div>
          <div style={{marginBottom: '3%'}}>


          {currentFlag === 'red' && <TextField   onChange={handleChangeRReason} id="outlined-basic" value={RReason} multiline inputProps={{paddingTop: 0, style: {minHeight: `100px`, maxHeight: `100px`, minWidth: `500px`, maxWidth: `500px`, textAlign: 'left'} }} variant="outlined" /> }
          {currentFlag === 'orange' && <TextField   onChange={handleChangeOReason} id="outlined-basic" value={OReason} multiline inputProps={{paddingTop: 0, style: {minHeight: `100px`, maxHeight: `100px`, minWidth: `500px`, maxWidth: `500px`, textAlign: 'left'} }} variant="outlined" />}
          {currentFlag === 'green' && <TextField   onChange={handleChangeGReason} id="outlined-basic" value={GReason} multiline inputProps={{paddingTop: 0, style: {minHeight: `100px`, maxHeight: `100px`, minWidth: `500px`, maxWidth: `500px`, textAlign: 'left'} }} variant="outlined" /> }
          {currentFlag === 'blue' && <TextField   onChange={handleChangeBReason} id="outlined-basic" value={BReason} multiline inputProps={{paddingTop: 0, style: {minHeight: `100px`, maxHeight: `100px`, minWidth: `500px`, maxWidth: `500px`, textAlign: 'left'} }} variant="outlined" /> }



  
          </div>
         {currentFlag != "" &&

          <Button variant="contained" onClick={updateFlags} style={{textAlign: "left", marginTop: "10px"}}> Save </Button>
        }

            </Box>   
        </Modal>
        </div>
    )
}

export default AddFlagModal;