import Modal from '@mui/material/Modal';
import { Grid, Box, Icon} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';


import DFindingTabs from '../dFindingTabs/DFindingTabs'
import RatingDFModal  from './RatingDFModal/RatingDFModal';
import PrevalenceDFModal from './PrevalenceDFModal/PrevalenceDFModal';
import ImageDFModal from './ImageDFModal/ImageDFModal';

function DiseaseFindingModal({isModalOpen, setIsModalOpen, dFinding, otherDiseases, onChange, setOnChange, updated, setUpdated}) {

    const style = {
        position: 'absolute',
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: "24vh",
        pt: "2vh",
        px: "4vh",
        pb: "3vh",
        maxHeight: '90vh',
        minHeight: '55vh',
        overflowY: 'auto',
      };

    const onClose = () => {
        setIsModalOpen(false)
    }

    const renderContent = () => {
      if(activeTab == "tab1") {
        return <RatingDFModal dFinding={dFinding} otherDiseases={otherDiseases} updated={updated} setUpdated={setUpdated} />
      }
      else if(activeTab == "tab2") {
        return <PrevalenceDFModal dFinding={dFinding} updatedNew={updated} setUpdatedNew={setUpdated}/>
      }
      else if(activeTab == "tab3") {
        return <ImageDFModal dFinding={dFinding} />
      }
    }

 
    const [activeTab, setActiveTab] = React.useState("tab1")

    return (
        <Modal
        open={isModalOpen}
        onClose={onClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, overflowY: 'auto', textAlign:'center'}}>
        <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
          <CloseIcon  onClick={onClose}/>
          </div>

          <DFindingTabs activeTab={activeTab} onTabChange={setActiveTab} />

          {renderContent()}



          </Box>
          </Modal>
 
    )
}

export default DiseaseFindingModal;
