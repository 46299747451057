import NavBar from "../../components/NavBar/NavBar";
import ImageTab from '../../components/ImageTab/ImageTab';

import React from "react";
import { Grid, Card, Box } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal } from '@mui/material';
import { LockOpen, Circle, Lock, TabUnselected, SnippetFolder } from "@mui/icons-material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import { styled, useTheme, alpha } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DiseaseFindingModal from "../disease/components/DiseaseFindingModal/DiseaseFindingModal";
import Tooltip from '@mui/material/Tooltip';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';

function FindingPage({selected, setSelected, filtered, setFiltered}) {

    const [diseases, setDiseases] = React.useState(filtered.filter((disease) => disease.type === "finding"))     //Contains the list of diseases selected from the select tab
    const [findings, setFindings] = React.useState([])                // Contains the list of all the findings that are currently displayed
    const [findingsSelected, setFindingsSelected] = React.useState([])//Indicates which findings are to be expanded when clicked 
    const [filteredSet, setFilteredSet] = React.useState(new Set())   //Contains a set of finding ids to look up all findigns that were selected
    const [diseaseFindings, setDiseaseFindings] = React.useState([])
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [selectedDFinding, setSelectedDFinding] = React.useState({})
    const [images, setImages] = React.useState([])
    const [imageIndex, setImageIndex] = React.useState([])
    const [onChange, setOnChange] = React.useState(false)
    const [onDFChange, setOnDFChange] = React.useState(false)
    const [photos, setPhotos] = React.useState([])
    const [updated, setUpdated] = React.useState(false)
    const [diseaseImages, setDiseaseImages] = React.useState(false);

    const theme = useTheme();
    const commonStyles = {
        bgcolor: 'background.paper',
        borderColor: 'text.primary',
        width: '300px',
        height: '300px',
        marginTop: '10px'
      };

      const style = {
        position: 'absolute',
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: "24vh",
        pt: "2vh",
        px: "4vh",
        pb: "3vh",
        maxHeight: '90vh',
        minHeight: '55vh',
      };



    React.useEffect(() => {
        const getAllNames = async () =>  {
    
          const findingNames = await axios.get(`https://api.clinicalphenomics.org/disease/names/0`)
          if(findingNames != "No disease exists") {
          setFindings(findingNames.data);
          }
          }
          getAllNames()

          for(var json_obj of filtered) {
            console.log(json_obj)
            if(json_obj.type == "disease") {
            const set = filteredSet.add(json_obj.id)
            setFilteredSet(set)
            }
          }

    }, [])



    React.useEffect(() => {



      const getDFindings = async() => {
        if(findings.length == 0 || diseases.length == 0 ){
          return;
        }



      const finding_ids = findings.map((finding) => finding.id)
      const disease_ids = diseases.map((disease) => disease.id)




      const payload = {
        disease_ids: finding_ids,
        finding_ids: disease_ids
      }

      console.log(payload)
      //Call the endpoint



      const dFindings = await axios.post(`https://api.clinicalphenomics.org/diseaseFinding/getDFArrayFinding`, payload)





      setDiseaseFindings(dFindings.data)

      }

      getDFindings();

    }, [findings, diseases, onDFChange, updated])


    React.useEffect(() => {
      const getImages = async () => {
        var imageArray = [];
        var indicies = [];
        for(const disease of diseases) {
          const images = await axios.get(`https://api.clinicalphenomics.org/image/all/finding/id/${disease.id}`)
          imageArray.push(images.data)
          indicies.push(images.data.length != 0 ? 1:0)
          if(images.data.length > 0) {
            const url = await axios.get(`https://api.clinicalphenomics.org/image/getImage/${images.data[0].id}`)
            photos.push(url.data)
            const img = new Image()
            img.src = url.data

          }
          else {
            photos.push("none")
          }
        }


        setImages(imageArray)
        setImageIndex(indicies)
        setPhotos(photos)


      }

      getImages()

    }, [diseases, onDFChange])


    const handleAccordionClick = async (event, id, index, unselect ) => {
        if(unselect) {
            const updatedDiseases = { ...findingsSelected};
            delete updatedDiseases[index]
            setFindingsSelected(updatedDiseases);
          }
    
          if(!unselect) {
           const children = await axios.get(`https://api.clinicalphenomics.org/disease/names/${id}`)
           const diseaseIds = diseases.map((disease) => disease.id)
           const findingIds = children.data.map((finding) => finding.id )

           const payload = {
            disease_ids: findingIds,
            finding_ids: diseaseIds
           }

           const dFinding = await axios.post('https://api.clinicalphenomics.org/diseaseFinding/getDFArrayFinding', payload)

           setFindingsSelected({
            ...findingsSelected,
          [index]: {children: children.data, dFinding: dFinding.data}
        })
        }
    }

    const iconSelection = (finding, index)=> {
        if(finding.hasChildren == 0) {
          return  <ExpandLessIcon style={{ visibility: 'hidden' }} />; 
        }
    
        else if(findingsSelected.hasOwnProperty(index)) {
          return (<ExpandLessIcon onClick={(event) => {event.stopPropagation(); handleAccordionClick(event, finding.id, index, true)}} 
          />); 
        }
    
        else {
          return ( <ExpandMoreIcon onClick={(event) => {event.stopPropagation(); handleAccordionClick(event, finding.id, index, false)}} 
          />) 
        }
      }

      const handleCameraClick = async () => {
        setDiseaseImages(true)
      }

      const onCloseImageModal = () => {
        setDiseaseImages(false)
    }

      const handleSelect = (finding) => {

        const isIdPresent = selected.find((disease) => disease.id === finding.id && disease.type === "disease");
        const filIdPresent = filtered.find((disease) => disease.id === finding.id && disease.type === "disease");
        const isInFilter = filteredSet.has(finding.id)


        if (!isIdPresent) {
          setSelected([...selected, {type:"disease", id: finding.id, name: finding.name, knowledge: finding.knowledge}]);
          }
  
        if (!filIdPresent) {
          setFiltered([...filtered, {type:"disease", id: finding.id, name: finding.name, knowledge: finding.knowledge}]);
        }

        if(!isInFilter) {
          const set = filteredSet.add(finding.id)
          setFilteredSet(set)
        }
      }

      const handleDeselect = (finding) => {
        const updatedArray = selected.filter(item => item.id !== finding.id || item.type != "disease");
        setSelected(updatedArray);
  
        const updatedAllArray = filtered.filter(item => item.id !== finding.id || item.type != "disease");
        setFiltered(updatedAllArray)

        filteredSet.delete(finding.id)

      }

      React.useEffect(() => {
        const updateDisease = async (id) => {
          var dFinding = await axios.get(`https://api.clinicalphenomics.org/diseaseFinding/${id}`)
          setSelectedDFinding(dFinding.data)
        }

        if(isModalOpen && selectedDFinding) {
          updateDisease(selectedDFinding.id)
        }

      }, [onChange])

  

      const handleDFindingSelect = async (id, disease_id, finding_id) => {

        var dFinding


        if(id == -1) {
          const payload = {
            disease_id: finding_id,
            finding_id: disease_id
          }
          dFinding = await axios.post(`https://api.clinicalphenomics.org/diseaseFinding/`, payload)
          setOnDFChange(!onDFChange)
        }

        else {
         dFinding = await axios.get(`https://api.clinicalphenomics.org/diseaseFinding/${id}`)
        }

        setIsModalOpen(true)
        setSelectedDFinding(dFinding.data)
      }

      const handleImageBackward = async (index) => {
        const length = images[index].length
        var imgIndex = 0;

        if(length == 0) {
          return;
        }

        let updated = [...imageIndex]


        if(updated[index] == 1) {
          updated[index] = length;
          imgIndex = length - 1;
          setImageIndex(updated)
        }

        else {
          updated[index]--;
          imgIndex = updated[index] - 1;
          setImageIndex(updated)
        }

        const url = await axios.get(`https://api.clinicalphenomics.org/image/getImage/${images[index][imgIndex].id}`)
        const img = new Image();
        img.src = url.data
        let newPhoto = [...photos]
        newPhoto[index] = url.data
        setPhotos(newPhoto)
      }

      const handleImageForward = async (index) => {
        const length = images[index].length
        let imgIndex;
        if(length == 0) {
          return;
        }

        
        let updated = [...imageIndex]

        if(updated[index] == length) {
          updated[index] = 1;
          imgIndex = 0;
        }
        else {
          updated[index]++;
          imgIndex = updated[index] - 1;
        }
        setImageIndex(updated)
        
        const url = await axios.get(`https://api.clinicalphenomics.org/image/getImage/${images[index][imgIndex].id}`)
        const img = new Image();
        img.src = url.data
        let newPhoto = [...photos]
        newPhoto[index] = url.data
        setPhotos(newPhoto)
      }

      const renderFindings =  (findings, parentIndex = null, diseaseFindings) => {
        console.log(diseaseFindings)
        console.log(findings)



        return findings.map((finding, index) => {
            const currentIndex = parentIndex !== null ? `${parentIndex}-${index}` : `${index}`;


            return (
            <>
            <TableRow style={{ backgroundColor: findingsSelected.hasOwnProperty(currentIndex) ? theme.palette.grey[200] : 'white' }} key={finding.id}>
              <TableCell >
                  <div  style={{display: 'flex', alignItems: 'center'}}> 
              {iconSelection(finding, currentIndex)}

              {!filteredSet.has(finding.id) && <Tooltip title="Select disease to view in disease tab"> <LockOpen onClick={() => handleSelect(finding)} style={{marginLeft: '10px', marginRight: '10px'}}/> </Tooltip>}
              {filteredSet.has(finding.id) && <Lock onClick={() => handleDeselect(finding)}  style={{marginLeft: '10px', marginRight: '10px'}}/>}

               {finding.numImages != 0 && <Tooltip title="Select to view images of disease"> <CameraAltIcon  style={{ marginBottom: '-7px',marginLeft: '0px',  marginRight: '10px'}} onClick={() => handleCameraClick()}/> </Tooltip>} 
               {finding.numImages == 0 && <Tooltip title="No images for disease"> <CameraAltIcon color="disabled" style={{ marginBottom: '-7px',marginLeft: '0px',  marginRight: '10px', }}/> </Tooltip>} 
               {console.log(finding)}

              {finding.name}

              {finding.numImages != 0 &&   <Modal
        open={diseaseImages}
        onClose={onCloseImageModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
          >
        <Box sx={{ ...style, textAlign:'center'}}>
        <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
          <CloseIcon  onClick={onCloseImageModal}/>
          </div>

          <ImageTab type="disease" entity={finding} edit={false}/> 


          </Box>
          </Modal> }

              
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>

                  <Box
               sx={{
                  top: 0,
                  left: 10,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
              </Box>        


      
              </Box>
              </div>
              </TableCell>


              {diseaseFindings.length != 0 && diseaseFindings[index].map((dFinding, ind) => (


                

                <TableCell style={{
                  cursor: 'pointer',
                  color: 'blue',      
                }} onClick={() => {
                  console.log(index);
                  console.log(diseaseFindings[index]);
                  handleDFindingSelect(dFinding.id, diseases[ind].id, finding.id )}} key={diseases.length * ind + index} >
                
                {dFinding.prevalence}  {!isNaN(parseFloat(dFinding.prevalence)) && "%"}

                </TableCell>

                
             ))}


            </TableRow>


           {findingsSelected.hasOwnProperty(currentIndex) && renderFindings(findingsSelected[currentIndex].children, currentIndex, findingsSelected[currentIndex].dFinding)}
           </>
        )})

      }

    const height = window.screen.height *211/12000+ 72.21



    return ( 

        <div  >
        <NavBar />

    <div style={{ }}>
        
      <TableContainer  component={Paper} style={{ height: height + "vh", margin: "0", padding: "0"}}>
        <Table style={{ minWidth: 800 }}>
          <TableHead>
            <TableRow >
              <TableCell style={{ minWidth: "300px"}} ></TableCell>
              {diseases.map((disease, index) => (

                <TableCell style={{ minWidth: "400px", alignItems: 'left'}} key={disease.id}>
              

                   {disease.name}

                  
                    
                   {photos[index]  && <Box sx={{bgcolor: 'background.paper', borderColor: 'text.primary', width: `400px`, height: `400px`, marginTop: '10px', border: 1 }}>
                  <img src={photos[index]} alt="Image" style={{ width: '100%', height: '100%' }} />
                </Box> }

                <Box sx={{width: '300px', marginTop: '10px', flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}> 
                <ArrowBackIosNewIcon onClick={() => handleImageBackward(index)} style={{ fontSize: '18px', cursor: 'pointer' }} />
                {console.log(imageIndex[index])}
                <span style={{ fontSize: '18px', margin: '0 10px' }}>{`${imageIndex[index]}/${images[index] != null ? images[index].length: 0}`}</span>
                <ArrowForwardIosIcon onClick={() => handleImageForward(index)} style={{ fontSize: '18px', cursor: 'pointer' }} />



                </Box>
                </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody >
            {renderFindings(findings, null, diseaseFindings)}
          </TableBody>
        </Table>
      </TableContainer>

      {isModalOpen && <DiseaseFindingModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} dFinding={selectedDFinding} otherDiseases={[]} onChange={onChange} setOnChange={setOnChange} updated={updated} setUpdated={setUpdated}/>}


    </div>



    </div>
  );
}

export default FindingPage;