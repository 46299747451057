import CommentIcon from '@mui/icons-material/Comment';
import Modal from '@mui/material/Modal';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { Grid, Box, Icon} from '@mui/material';
import { ThumbUp, Flag, Reply, ModeEdit, Delete, ArrowBackIosNew } from '@mui/icons-material';
import { Divider } from '@mui/material'
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Container, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';

import AddFlagModal from '../AddFlagModal/AddFlagModal';
import SeeFlagModal from '../SeeFlagModal/SeeFlagModal';

import { DatePicker } from '@mui/lab';

function CommentModal({type, id, commentStyle}) {

    const [open, setOpen] = React.useState(false)
    const [updatedComment,setUpdatedComment] = React.useState("")
    const [user, setUser] = React.useState({})
    const [seeFlagOpen, setSeeFlagOpen] = React.useState("")

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleDeleteComment = async (id) => {
      await axios.delete(`https://api.clinicalphenomics.org/comment/${id}`, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })
    }

    React.useEffect( () => {
      const getUser = async () => {
        if(document.cookie == "") {
          return;
        }
        const user = await axios.get("https://api.clinicalphenomics.org/currUserInfo", {
          headers: {
            Authorization: `Bearer ${document.cookie}`
          }
        })

        setUser(user.data)


      }

      getUser()
    }, [])

    const style = {
        position: 'absolute',
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: "24vh",
        pt: "2vh",
        px: "4vh",
        pb: "3vh",
        maxHeight: '90vh',
        overflowY: 'auto',
      };

      const [newComment, setNewComment] = React.useState("Please enter your comment here ...")
      const [allComments, setAllComments] = React.useState([])
      const [renderParent, setRenderParent] = React.useState({})
      const [childComments, setChildComments] = React.useState([])
      const [editMode, setEditMode] = React.useState(-1)
      const [likedMap, setLikedMap] = React.useState(new Map())
      const [liked, setLiked] = React.useState(false)

      const handleLike = async (id) => {
        if(likedMap.has(String(id))) {

          await axios.delete(`https://api.clinicalphenomics.org/raiting/CRI/${String(likedMap.get(String(id)))}`, {
            headers: {
              Authorization: `Bearer ${document.cookie}`
            }
          })



        }
        else {
          const payload = {
            type: "Comment",
            liked: true,
            type_id: id
          }
          await axios.post(`https://api.clinicalphenomics.org/raiting/CRI`, payload, {
            headers: {
              Authorization: `Bearer ${document.cookie}`
            }
          })
        }

        console.log(!liked)


        setLiked(!liked)
      }


      React.useEffect(() => {
        const getLikes = async () => {
          if(allComments.length == 0) {
            return;
          }

          if(Object.keys(renderParent) != 0) {
            return;
          }

          console.log("Liked removed !!!!!!!!!!!")

          const ids = allComments.map((comment) => comment.id)
          const payload = {
            ids: ids
          }

          const likesMap = await axios.post("https://api.clinicalphenomics.org/raiting/CRI/comment/getByUser", payload, {
            headers: {
              Authorization: `Bearer ${document.cookie}`
            }
          })

          console.log(likesMap.data)


          setLikedMap(new Map(Object.entries(likesMap.data)))
          

        }


        if(document.cookie != ""){
        getLikes()
        }
      
      }, [allComments, liked, renderParent ])

      React.useEffect(() => {
        const fetchEntity = async () => {
          const newEntity = await axios.get(`https://api.clinicalphenomics.org/comment/children/type/${type}/id/${id}`)
        setAllComments(newEntity.data);

        }
        fetchEntity()
      }, [open, editMode, liked])

      const handleChangeComment = (event) => {
        setNewComment(event.target.value)
      }

      const handleDisplayChildComments = async (event, comment) => {
        setRenderParent(comment);

      }

      const handleUpdateChange =  (event) => {
        setUpdatedComment(event.target.value)

      } 

      const handleEditModeChange =  (id, comment) => {
        setUpdatedComment(comment)
        setEditMode(id)
      }


      const handleUpdateComent = async () => {
        const payload = {
          "body": updatedComment
        }

        if(updatedComment == "") {
          alert("Your comment cannot be empty");
          return
         }

        await axios.put(`https://api.clinicalphenomics.org/comment/${editMode}`, payload, {
          headers: {
            Authorization: `Bearer ${document.cookie}`
          }
        })

        setEditMode(-1)
        
        
      }

      React.useEffect(() => {

        async function addParentComment() {
        const newEntity = await axios.get(`https://api.clinicalphenomics.org/comment/children/${renderParent.id}`)
        
        const ids = newEntity.data.map((entity) => entity.id)
        const payload = {
          ids: ids
        }

        if(document.cookie != "") {

        const likesMap = await axios.post("https://api.clinicalphenomics.org/raiting/CRI/comment/getByUser", payload, {
          headers: {
            Authorization: `Bearer ${document.cookie}`
          }
        })

        setLikedMap(new Map(Object.entries(likesMap.data)))
      }
        setChildComments(newEntity.data)
       }

        if(Object.keys(renderParent).length != 0) {
            addParentComment()
        }

      }, [allComments, renderParent, liked])

      const returnToPrevious = () => {
        setRenderParent({})
        setChildComments([])
      }


      const renderComments = () => {
        return (
            <div>
            <h1>Comments</h1>
            <Paper style={{ padding: "20px" }}>
            <Grid container direction="column" spacing={1}> 
            <Grid item>
               <h4 style={{ marginBottom: "20px", textAlign: "left", fontSize: "18px" }}> Add New Comment: </h4>
               <TextField  onChange={handleChangeComment} id="outlined-basic" value={newComment} multiline inputProps={{paddingTop: 0, style: {  minHeight: `70px`, maxHeight: `70px`, minWidth: `500px`, maxWidth: `500px`, textAlign: 'left'} }} variant="outlined" />
 
 
               <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
               </div>
               <div style={{ display: "flex", justifyContent: "flex-start" }}>
               <Button variant="contained" onClick={handleCommentCreate} style={{textAlign: "left", marginTop: "10px"}}> Post </Button>
               </div>
 
             </Grid>
             <Divider variant="fullWidth" style={{ margin: "10px 0" }} />
             
             {allComments.map(comment => ( 
 
             <div>
             <Grid item>
             <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>


                <h4 style={{ margin: 0, textAlign: "left" }}>{comment.User && comment.User.first_name} {comment.User &&  comment.User.last_name} </h4>
                <Tooltip title="Question">
                <Flag onClick={() => {setSeeFlagOpen("Question")}} style={{ color: 'red' , marginLeft: '10px'}}  />
                </Tooltip>
                {seeFlagOpen =="Question" && <SeeFlagModal type = "Comment" id={comment.id} flag_type="Question" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
                 <span> {comment.numQFlags}</span>
                 <Tooltip title="Comment">
                 <Flag onClick={() => {setSeeFlagOpen("Comment")}}  style={{ color: 'orange' }}  />
                 </Tooltip> 
                 {seeFlagOpen =="Comment" && <SeeFlagModal type = "Comment" id={comment.id} flag_type="Comment" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
                 <span> {comment.numCFlags} </span>
                 <Tooltip title="Verify">
                <Flag onClick={() => {setSeeFlagOpen("Verify")}} style={{ color: 'green' }}  />
                </Tooltip>
                {seeFlagOpen =="Verify" && <SeeFlagModal type = "Comment" id={comment.id} flag_type="Verify" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
                <span> {comment.numVFlags} </span>
                <Tooltip title="Review">
                <Flag onClick={() => {setSeeFlagOpen("Review")}}  style={{ color: 'blue' }}  />
                </Tooltip>
                {seeFlagOpen =="Review" && <SeeFlagModal type = "Comment" id={comment.id} flag_type="Review" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
               <span> {comment.numRFlags} </span>

            </div>
               {editMode == comment.id && 
               <> 

               <div style={{ display: 'flex', justifyContent: 'center' }}>
               <TextField id="outlined-basic"  multiline value={updatedComment} onChange={handleUpdateChange} inputProps={{paddingTop: 0,  style: {  minHeight: `70px`, maxHeight: `70px`, minWidth: `500px`, maxWidth: `500px`, textAlign: 'left'} }} variant="outlined"/>

               </div>
            <Button variant="contained" onClick = {handleUpdateComent} style={{textAlign: "left", marginTop: "10px"}}> Update </Button>
            </>

      }

           {!(editMode == comment.id)&& 
           <>
               <p style={{ textAlign: "left" }}>
                 {comment.body}
               </p>
 

               <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                 <IconButton>


                   <ThumbUp onClick={() => handleLike(comment.id)} color={likedMap.has(String(comment.id)) ? "primary" : "default"} style={{ fontSize: '23' }}/>
                  
                 </IconButton>
                 <span style={{ fontSize: '23' }}> {comment.num_of_likes} </span>
                 <IconButton>
                   <AddFlagModal imageType = "flag" imageStyle={{fontSize: '23'}} type="Comment" entity={comment} />
                 </IconButton>
                 <IconButton>
                   <Reply onClick={(event) => handleDisplayChildComments(event, comment)}style={{ fontSize: '23' }}/>
                 </IconButton>

                 {user.id == comment.UserId &&
                 <IconButton>
                   <ModeEdit onClick={() => handleEditModeChange(comment.id, comment.body)} />
                 </IconButton>
                }

                {user.id == comment.UserId &&
                 <IconButton>
                   <Delete onClick={() => handleDeleteComment(comment.id)} />
                 </IconButton>
                }
               </div>
               </>
      }
             </Grid>
             <Divider variant="fullWidth"  style={{ margin: "10px 0" }} />
             </div>
           ))}
 
             </Grid>
 
 
 
            </Paper>
            </div>
        )
    }


    const renderChildComments = () => {

        return (
            <div>
             <Box display="flex" alignItems="center">

            <ArrowBack onClick={returnToPrevious}/>

            <h1 style={{ marginLeft: '10px', flex: 1 }}>Comments</h1>
            </Box> 

            <Paper style={{ padding: "20px" }}>
            <Grid container direction="column" spacing={1}> 

            <Grid item>

                  <h4 style={{ margin: 0, textAlign: "left" }}> {renderParent.User && renderParent.User.first_name} {renderParent.User &&  renderParent.User.last_name}  </h4>


               <p style={{ textAlign: "left" }}>
                 {renderParent.body}
               </p>
 
               <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                 <IconButton>
                   <ThumbUp  style={{ fontSize: '23' }}/>
                 </IconButton>
                 <span style={{ fontSize: '23' }}>{renderParent.num_of_likes}</span>
                 <IconButton>
                   <Flag style={{ fontSize: '23' }}/>
                 </IconButton>
               </div>
             </Grid>

             <Divider variant="fullWidth" style={{ margin: "10px 0" }} />
             
            <Grid item>
               <h4 style={{ marginBottom: "20px", textAlign: "left", fontSize: "18px" }}> Reply To Comment: </h4>
               <TextField  onChange={handleChangeComment} id="outlined-basic" value={newComment} multiline inputProps={{paddingTop: 0, style: {  minHeight: `70px`, maxHeight: `70px`, minWidth: `500px`, maxWidth: `500px`, textAlign: 'left'} }} variant="outlined" />
 
 
               <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
               </div>
               <div style={{ display: "flex", justifyContent: "flex-start" }}>
               <Button variant="contained" onClick={handleParentCommentCreate} style={{textAlign: "left", marginTop: "10px"}}> Post </Button>
               </div>
 
             </Grid>
             <Divider variant="fullWidth" style={{ margin: "10px 0" }} />
             
             {childComments.map(comment => ( 
 
             <div>
                            {console.log(comment)}

             <Grid item>
             <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
             <h4 style={{ margin: 0, textAlign: "left" }}> {comment.User && comment.User.first_name} {comment.User &&  comment.User.last_name}  </h4>
                <Tooltip title="Question">
                <Flag onClick={() => {setSeeFlagOpen("Question")}} style={{ color: 'red' , marginLeft: '10px'}}  />
                </Tooltip>
                {seeFlagOpen =="Question" && <SeeFlagModal type = "Comment" id={comment.id} flag_type="Question" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
                 <span> {comment.numQFlags}</span>
                 <Tooltip title="Comment">
                 <Flag onClick={() => {setSeeFlagOpen("Comment")}}  style={{ color: 'orange' }}  />
                 </Tooltip>
                 {seeFlagOpen =="Comment" && <SeeFlagModal type = "Comment" id={comment.id} flag_type="Comment" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
                 <span> {comment.numCFlags} </span>
                 <Tooltip title="Verify">
                <Flag onClick={() => {setSeeFlagOpen("Verify")}} style={{ color: 'green' }}  />
                </Tooltip>
                {seeFlagOpen =="Verify" && <SeeFlagModal type = "Comment" id={comment.id} flag_type="Verify" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
                <span> {comment.numVFlags} </span>
                <Tooltip title="Review">
                <Flag onClick={() => {setSeeFlagOpen("Review")}}  style={{ color: 'blue' }}  />
                </Tooltip>
                {seeFlagOpen =="Review" && <SeeFlagModal type = "Comment" id={comment.id} flag_type="Review" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
               <span> {comment.numRFlags} </span>

              </div>
               {editMode == comment.id && 
               <> 

               <div style={{ display: 'flex', justifyContent: 'center' }}>
               <TextField id="outlined-basic"  multiline value={updatedComment} onChange={handleUpdateChange} inputProps={{paddingTop: 0,  style: {  minHeight: `70px`, maxHeight: `70px`, minWidth: `500px`, maxWidth: `500px`, textAlign: 'left'} }} variant="outlined"/>

               </div>
            <Button variant="contained" onClick = {handleUpdateComent} style={{textAlign: "left", marginTop: "10px"}}> Update </Button>
            </>

      }
                 {!(editMode == comment.id)&& 
           <>

               <p style={{ textAlign: "left" }}>
                 {comment.body}
               </p>
               <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                 <IconButton>
                   <ThumbUp  color={likedMap.has(String(comment.id)) ? "primary" : "default"} onClick={() => handleLike(comment.id)} style={{ fontSize: '23' }}/>
                 </IconButton>
                 <span style={{ fontSize: '23' }}> {comment.num_of_likes}</span>
                 <IconButton>
                 <AddFlagModal imageType = "flag" imageStyle={{fontSize: '23'}} type="Comment" entity={comment} />

                 </IconButton>
                 
                 {user.id == comment.UserId &&
                 <IconButton>
                   <ModeEdit onClick={() => handleEditModeChange(comment.id, comment.body)} />
                 </IconButton>
                }

               {user.id == comment.UserId &&
                 <IconButton>
                    <Delete onClick={() => handleDeleteComment(comment.id)} />
                 </IconButton>
                } 
               </div>
               </>
               }
             </Grid>
             <Divider variant="fullWidth" style={{ margin: "10px 0" }} />
             </div>
           ))}
 
             </Grid>
 
 
 
            </Paper>
            </div>
        )


    }

    
    const handleParentCommentCreate = async () => {
       let payload = {
        "body": newComment, 
        "comment_id": renderParent.id
       }   


       if(document.cookie == "") {
        alert("You need to be register or log in to add a comment.")
        return;
       }

       if(newComment == "") {
        alert("Your comment cannot be empty");
        return
       }

       const newEntity = await axios.post(`https://api.clinicalphenomics.org/comment`, payload,
       {
         headers: {
           Authorization: `Bearer ${document.cookie}`,
         },
      })

      setNewComment(" ")


    }


      const handleCommentCreate = async () => {
        let payload = {}

        if(document.cookie == "") {
          alert("You need to be logged in or registered to add a comment.")
          return;
         }

         if(newComment == "") {
          alert("Your comment cannot be empty");
          return
         }


        if(type == "disease") {
            payload = {body: newComment, type: "Disease", type_id: id}
        }

        else if(type == "finding") {
            payload = {body: newComment, type: "Finding", type_id: id}
        }

        else if(type == "reference") {
          payload = {body: newComment, type: "Reference", type_id: id}
      }

        else if(type == "image") {
          payload = {body: newComment, type: "Image", type_id: id}
        }



        const newEntity = await axios.post(`https://api.clinicalphenomics.org/comment`, payload,
            {
              headers: {
                Authorization: `Bearer ${document.cookie}`,
              },
        })

        setNewComment(" ")
      }

      


    return (
        <div> 

        <IconButton> 
        <CommentIcon style={commentStyle} color="primary" onClick={handleOpen}/>
        </IconButton>

        <Modal open={open}              
               onClose={handleClose}
               aria-labelledby="parent-modal-title"
               aria-describedby="parent-modal-description">
          <Box sx={{ ...style, overflowY: 'auto', textAlign:'center'}}>
          <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
              <CloseIcon  onClick={handleClose}/>
           </div>


           {Object.keys(renderParent).length == 0 && renderComments()}
           {Object.keys(renderParent).length != 0 && renderChildComments()}


  

        </Box>
        </Modal>
    </div>
    )

}

export default CommentModal;