import Button from '@mui/material/Button';


function OptButton({label, marg, leftmarg, onClick, disabled}) {
    return (
      <Button onClick={onClick} style={{marginTop: marg, marginLeft: leftmarg}} variant="contained" disabled={disabled}> {label} </Button>
    )
  }

  OptButton.defaultProps = {
    disabled: false,
  };

  export default OptButton;