import React from 'react';
import { Grid, Box, Icon, MenuItem} from '@mui/material';
import { DataGrid, GridToolbarContainer, GridEditInputCell } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import {GridToolbarColumnsButton,  GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport} from '@mui/x-data-grid';
import axios from "axios";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ThumbUp, Flag, Reply, ModeEdit, ArrowBackIosNew, InvertColorsOff, EditTwoTone } from '@mui/icons-material';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

import CommentModal from '../../../../../components/CommentModal/CommentModal'
import AddFlagModal from '../../../../../components/AddFlagModal/AddFlagModal'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SeeFlagModal from '../../../../../components/SeeFlagModal/SeeFlagModal';



function PrevalenceDFModal({dFinding, updatedNew, setUpdatedNew}) {

  const [DFRefs, setDFRefs] = React.useState([])
  const [selected, setSelected] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [maxId, setMaxId] = React.useState(0);
  const [origCopy, setOrigCopy] = React.useState([])
  const [prevType, setPrevType] = React.useState([]);
  const [likedMap, setLikedMap] = React.useState(new Map())
  const [liked, setLiked] = React.useState(false)
  const [seeFlagOpen, setSeeFlagOpen] = React.useState("")



  const handleLike = async (id) => {
    if(likedMap.has(String(id))) {

      await axios.delete(`https://api.clinicalphenomics.org/raiting/CRI/${String(likedMap.get(String(id)))}`, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })



    }
    else {
      const payload = {
        type: "Reference",
        liked: true,
        type_id: id
      }
      await axios.post(`https://api.clinicalphenomics.org/raiting/CRI`, payload, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })
    }

    console.log(!liked)


    setLiked(!liked)
  }

  React.useEffect(() => {
    const getLikes = async () => {
      if(DFRefs.length == 0) {
        return;
      }

      if(editMode) {
        return;
      }

      console.log("Liked removed !!!!!!!!!!!")

      const ids = DFRefs.map((comment) => comment.id)
      const payload = {
        ids: ids
      }

      const likesMap = await axios.post("https://api.clinicalphenomics.org/raiting/CRI/reference/getByUser", payload, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })

      console.log(likesMap.data)


      setLikedMap(new Map(Object.entries(likesMap.data)))
      

    }


    if(document.cookie != ""){
    getLikes()
    }
  
  }, [DFRefs, liked ])



  

  const handleAddRow = () => {

    let newRows = [...DFRefs]; 
    let newRow = {id: maxId, newValue: true};
    let newId = maxId + 1
    setMaxId(newId); 
    newRows.push(newRow); 
    setDFRefs(newRows); 
  };



  
  const handleProcessRowUpdateError = (error) => {
    console.error('Error updating row:', error);
    };

  const handleCellEditCommit = (params) => {
    const rowIndex = DFRefs.findIndex(row => row.id == params.id);
    if (rowIndex !== -1) {
    const updatedRows = [...DFRefs];
    const updatedParams = {...params}
    updatedRows[rowIndex] = updatedParams
    setDFRefs(updatedRows)
    return updatedRows[rowIndex]
  }
}

const handleValidation  = (ref) => {
  ref.pmid = ref.pmid || 0
  ref.num_patients = ref.num_patients || 0
  ref.percentage = ref.percentage === undefined ? "": ref.percentage


  if(isNaN(ref.pmid) || parseInt(ref.pmid) < 0) {
    alert("You must enter a number greater than 0 for PMID")
    return false
  }

  if(isNaN(ref.num_patients) || parseInt(ref.num_patients) < 0) {
    alert("You must enter a number greater than 0 for number of patients")
    return false
  }

  if(!Boolean(ref.prevType)) {
    alert("You must choose either subjective or absolute reference type")
    return false
  }

  if(ref.prevType == "Subjective" && !Boolean(ref.subjective)) {
    alert("You must choose a % option below")
    return false
  }

  if(ref.prevType == "Absolute" && isNaN(ref.percentage) || parseInt(ref.percentage) < 0 || parseInt(ref.percentage) > 100) {
    alert("You must enter a number between 0 and 100 for %")
    return false
  }


  return true
}

const handleUpdate = async () => {
  const updatedOrNew = DFRefs.filter((DRef) => !origCopy.includes(DRef))
  var updated = updatedOrNew.filter((DRef) => !DRef.newValue)
  var newRef = updatedOrNew.filter((DRef) => DRef.newValue)
  var deletedOrUpdated = origCopy.filter((ref) => (!DFRefs.includes(ref)))
  var updatedIds = updated.map(DRef => DRef.id);
  var deleted = deletedOrUpdated.filter((ref) => !updatedIds.includes(ref.id))

  for(const ref of deleted) {
    await axios.delete(`https://api.clinicalphenomics.org/reference/${ref.id}`, {
      headers: {
        Authorization: `Bearer ${document.cookie}`
      }
    })

  }

  for(const ref of updated) {
    if(!handleValidation(ref)) {
      return;
    }

    console.log(ref)


    let payload = {...ref}
    console.log(payload)

    delete payload["id"]
    delete payload["newValue"]

    payload["percentage"] = ref.percentage != null ? parseFloat(ref.percentage): null

    if(payload["prevType"] == "Absolute") {
      payload["subjective"] = null

    } else if(payload["prevType"] == "Subjective") {
      if(payload.subjective == "") {
        alert("Your reference must contain a prevalence with an subjective reference")
        return
      }
      payload["percentage"] = null
    }


    delete payload["prevType"]


    await axios.put(`https://api.clinicalphenomics.org/reference/${ref.id}`, payload, {
      headers: {
        Authorization: `Bearer ${document.cookie}`
      }
    })
  }


  for(const ref of newRef) {
    if(!handleValidation(ref)) {
      return;
    }

    console.log(newRef)
    let payload = {...ref}
    delete payload["id"]
    delete payload["newValue"]
    payload["type"] = "Disease Finding"
    payload["type_id"] = dFinding.id
    payload["percentage"] = parseFloat(ref.percentage)
    payload["num_of_likes"] = 0

 

    
    if(payload["prevType"] == "Absolute") {
      delete payload["subjective"]

    } else if(payload["prevType"] == "Subjective") {
      if(payload.subjective == "") {
        alert("Your reference must contain a prevalence with an subjective reference")
        return
      }
      console.log(payload)

      delete payload["percentage"]
    }

    delete payload["prevType"]

    

    console.log(payload)

    await axios.post(`https://api.clinicalphenomics.org/reference/`, payload, {
      headers: {
        Authorization: `Bearer ${document.cookie}`
      }
    })
  }

  setSelected([])
  setUpdatedNew(!updatedNew)
  setEditMode(false)
}

const handleDelete =   () => {
  const filtered = DFRefs.filter((obj) => obj.id != selected.id)
  setDFRefs(filtered)
  setSelected([])
}



  const handleEditMode = async () =>{
    if(document.cookie == "") {
      alert("You must be logged in to post a reference")
      return;
    }

    
    const newEntity = await axios.get(`https://api.clinicalphenomics.org/reference/user/dFinding/${dFinding.id}`,             
    {
      headers: {
        Authorization: `Bearer ${document.cookie}`,
      },
    })

    let maxNum = 0;


    for (let i = 0; i < newEntity.data.length; i++) {
      if(newEntity.data[i].percentage != null) {
      newEntity.data[i].percentage =  parseFloat(newEntity.data[i].percentage);
      }      

      if(newEntity.data[i].id > maxNum) {
        maxNum = newEntity.data[i].id
      }

      if(newEntity.data[i].subjective) {
        newEntity.data[i].prevType = "Subjective"
      }
      else {
        newEntity.data[i].prevType = "Absolute"
      }



      newEntity.data[i].newValue = false
    }

    maxNum++;

    
    setMaxId(maxNum)
    setDFRefs(newEntity.data)

    const copy = [...newEntity.data]
    setOrigCopy(copy)


    setEditMode(true)




    
  }




  React.useEffect(() =>{

    const fetchEntity = async () => {
      const newEntity = await axios.get(`https://api.clinicalphenomics.org/reference/dFinding/${dFinding.id}`)
      for (let i = 0; i < newEntity.data.length; i++) {
      if(!newEntity.data[i].subjective && newEntity.data[i].percentage != null) {
        console.log("HERE")
        newEntity.data[i].percentage =  parseFloat(newEntity.data[i].percentage);
        }
        if(newEntity.data[i].subjective) {
          newEntity.data[i].prevType = "Subjective"
        }
        else {
          newEntity.data[i].prevType = "Absolute"
        }

        if(selected.length != 0 && selected.id == newEntity.data[i].id) {
          const newArr = {...selected}
          newArr.row = newEntity.data[i]
          setSelected(newArr)
        }
      }
    setDFRefs(newEntity.data);

    }
    if(!editMode) {
    fetchEntity()
    }

  }, [editMode, liked])


  
    
  const columns = [

    {
      field: 'pmid',
      headerName: 'PMID',
      width: 120,
      editable: editMode,
      valueGetter: (params) => {return params.value || ""}

    },

    {field: 'reference',
     headerName: 'Reference Link',
     width: 350,
     editable: editMode,

  },


  {
    field: 'prevType',
    headerName: 'type',
    width: 180,
    editable: editMode,
    renderCell: (params) => {

        if(!editMode) {
          return (params.row.prevType)
        }

        return(
            <FormControl fullWidth>


            <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={params.row.prevType || ""}
                      style={{ width: `${params.colDef.width}px` }} 
                      onChange = { (event) =>    {
                        const updatedRow = {...params.row, prevType: event.target.value };                    
                        handleCellEditCommit(updatedRow);
                        return updatedRow
                      }}

            >

                <MenuItem value="Absolute">Absolute </MenuItem>
                <MenuItem value="Subjective">Subjective </MenuItem>

            </Select>
            </FormControl>


        )


    }
  },

  {
    field: "percentage",
    headerName: '%',
    width: 150,
    editable: editMode,
    valueGetter: (params) => {return params.value != null ? params.value : ""},
    renderCell: (params) => {
      if(!editMode) {

        if(params.row.prevType == "Absolute") {
          return  params.row.percentage;

        } else {
        return (params.row.subjective)
        }
      }

        if(params.row.prevType == "Absolute") {
            return <GridEditInputCell {...params} />;
        }

        else {


        return (

            <FormControl  fullWidth>


            <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={params.row.subjective || "" }
                      onChange={(event) => {
                        const updatedRow = {...params.row, subjective: event.target.value };
                        console.log(updatedRow)
                        handleCellEditCommit(updatedRow);
                      }
                      }
            > 

            {console.log(params)}

                <MenuItem value="--">Almost Always Not Present </MenuItem>
                <MenuItem value="-">Usually Not Present </MenuItem>
                <MenuItem value="+/-">Variable </MenuItem>
                <MenuItem value="+"> Usually Present </MenuItem>
                <MenuItem value="++"> Almost Always Present </MenuItem>


            </Select>
            </FormControl>

        )
        }

    }
    
  },

  {field: "num_patients",
   headerName: "Number of Patients",
   width: 200, 
   editable: editMode,
   valueGetter: (params) => {return params.value || ""}}


  ]

  const handleRowClick = (rowData, event) => {
    setSelected(rowData);
  }




  
  return (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>



    <Box sx={{ height: 400, width: '100%' }}>
    <h2>
  Add Prevalence References
  <ModeEditIcon color="primary" style={{marginLeft: "10px"}} onClick={handleEditMode} />

</h2>
<div style={{ position: 'relative', top: '0px', left: '0px' }}>

    <DataGrid
      rows={DFRefs}
      columns={columns}
      processRowUpdate={handleCellEditCommit}
      onProcessRowUpdateError={(error, updatedRows, params) => handleProcessRowUpdateError(error, updatedRows, params)}  
      onRowClick={handleRowClick}
      slots={{

        toolbar: () => (
          <GridToolbarContainer> {editMode && 
            <IconButton color="primary" aria-label="add" onClick={handleAddRow}> + </IconButton> }

            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />

          </GridToolbarContainer>
        )
        
      }}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 3,
          },
        },
      }}
      pageSizeOptions={[5]}

    />

    
          </div>


          {(selected.length != 0 && !editMode) &&
          <>
  <div style={{ display: 'flex', justifyContent: 'center' }}> 

<CommentModal type="reference" id={selected.id}  commentStyle={{ height: '30px', marginRight: '20px', marginTop: '6px' }} />
  <ThumbUp onClick={() => handleLike(selected.id)} color={likedMap.has(String(selected.id)) ? "primary" : "default"} style={{ height: '30px', margin: '10px 10px 10px 30px' }} />
  <span style={{marginTop: '15px', marginRight: '15px'}}>  {selected.row.num_of_likes}</span>
  <AddFlagModal imageType="plus" imageStyle={{ height: '30px', marginLeft: '20px', marginTop: '10px' }} type="Reference" entity={selected} />
  <Tooltip title="Question">

    <Flag  onClick={() => {setSeeFlagOpen("Question")}} style={{ color: 'red',  marginTop: '10px'}}  />
    </Tooltip>
    {seeFlagOpen =="Question" && <SeeFlagModal type = "Reference" id={selected.id} flag_type="Question" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
    <span style={{marginTop: '12px'}}>  {selected.row.numQFlags}</span>
    <Tooltip title="Comment">
    <Flag onClick={() => {setSeeFlagOpen("Comment")}} style={{ color: 'orange', marginTop: '10px'}}  />
    </Tooltip>
    {seeFlagOpen =="Comment" && <SeeFlagModal type = "Reference" id={selected.id} flag_type="Comment" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
    <span style={{marginTop: '12px'}}> {selected.row.numCFlags} </span>
    <Tooltip title="Verify">
    <Flag onClick={() => {setSeeFlagOpen("Verify")}} style={{ color: 'green', marginTop: '10px'}}   />
    </Tooltip>

    {seeFlagOpen =="Verify" && <SeeFlagModal type = "Reference" id={selected.id} flag_type="Verify" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
    <span style={{marginTop: '12px'}} > {selected.row.numVFlags} </span>
    <Tooltip title="Review"> 
    <Flag onClick={() => {setSeeFlagOpen("Review")}} style={{ color: 'blue', marginTop: '10px'}}   />
    </Tooltip>
    {seeFlagOpen =="Review" && <SeeFlagModal type = "Reference" id={selected.id} flag_type="Review" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
    <span style={{marginTop: '12px'}}> {selected.row.numRFlags} </span>

    





  </div>

{(!editMode && selected.row && selected.row.User) && 

<div>
    
  <TableContainer   component={Paper}>
  <Table  sx={{ minWidth: 650 }} aria-label="simple table">
   <TableBody >

   <TableRow 
        key={selected.row.User.first_name}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
         Created By
        </TableCell>
        {selected.row.User != null && <TableCell align="right"> {selected.row.User.first_name} {selected.row.User.last_name} </TableCell>}

      </TableRow>

   </TableBody>

  </Table>
  </TableContainer>
  <div style={{ height: '20px' }}></div>

  </div>
  } 

  </>
    
 }
 

 {editMode && <Button variant="contained" onClick={handleUpdate} style={{marginTop: "10px"}}  color="primary" > Save </Button>}
 {(editMode && DFRefs.length > 0) && <Button variant="contained" onClick={handleDelete} style={{marginTop: "10px", marginLeft: "10px"}}  color="error" > Delete Selected </Button>}


 {console.log(selected)}
   
  </Box>
  
  </div>


  )}



export default PrevalenceDFModal;