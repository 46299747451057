import { Container, TextField } from "@mui/material";
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import axios from "axios";

function SearchBar({label, type, selected, setSelected, filtered, setFiltered}) {

  const [options, setOptions] = React.useState([])
  const [search, setSearch] = React.useState("")

  const onOptionChange = async (event, value) => {
    setSearch(value)
    const exclude = filtered.filter((disease) => disease.type == type).map((disease) => disease.id)
    

    const payload = {
      search: value,
      exclude: exclude
    }

    const entities = await axios.post(`https://api.clinicalphenomics.org/${type}/getBySearch`, payload) 

    setOptions(entities.data)

    console.log(entities.data)

  }

  const selectDisease = (event, value) => {

    if(value == null) {
      return;
    }
    const entity = {...value, type: type}
    const isIdPresent = selected.find((disease) => disease.id === value.id && disease.type === value.type);
    const filIdPresent = filtered.find((disease) => disease.id === value.id && disease.type === value.type);

    if (!isIdPresent) {
    setSelected([...selected, entity]);
    }

    if (!filIdPresent) {
    setFiltered([...filtered, entity]);
    }



  }

  const isOptionEqualToValue = (option, value) => {


    return option.id === value.id;
  };



    return (
      <>


        <Container>
          <Autocomplete
           options={options}
           onInputChange={onOptionChange}
           onChange={selectDisease}
           getOptionLabel={(option) => option.name}
           isOptionEqualToValue={isOptionEqualToValue}
         renderInput={(params) => <TextField  {...params}   label={label} value={search} style={{ minWidth: '15vw', maxWidth: '15vw'}}> </TextField>}
         renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
         /> 

        </Container>
      </>
    );
  
}

export default SearchBar;

//           renderInput={(params) =>  <TextField  label = {label}   inputProps={{style: {minHeight: '3vh', maxHeight: '3vh'} }}
//style={{ minWidth: '15vw', maxWidth: '15vw'}} type="search" id="search"/>
//} 