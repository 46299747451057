import ImageTab from "../../../../../components/ImageTab/ImageTab"

function ImageDFModal({dFinding})  {

    return (
        <ImageTab type="Disease Finding" entity={dFinding} />
    )

}

export default ImageDFModal