import NavBar from "../../components/NavBar/NavBar";
import ImageTab from '../../components/ImageTab/ImageTab';

import React from "react";
import { Grid, Card, Box } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal } from '@mui/material';
import { LockOpen, Circle, Lock, TabUnselected, SnippetFolder } from "@mui/icons-material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { styled, useTheme, alpha } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Popover from '@mui/material/Popover';
import DiseaseFindingModal from "./components/DiseaseFindingModal/DiseaseFindingModal"
import Matrix from "./components/Matrix/Matrix"
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';

function DiseasePage({selected, setSelected, filtered, setFiltered}) {

    const [diseases, setDiseases] = React.useState(filtered.filter((disease) => disease.type === "disease"))     //Contains the list of diseases selected from the select tab
    const [findings, setFindings] = React.useState([])                // Contains the list of all the findings that are currently displayed
    const [findingsSelected, setFindingsSelected] = React.useState([])//Indicates which findings are to be expanded when clicked 
    const [filteredSet, setFilteredSet] = React.useState(new Set())   //Contains a set of finding ids to look up all findigns that were selected
    const [diseaseFindings, setDiseaseFindings] = React.useState([])
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [selectedDFinding, setSelectedDFinding] = React.useState({})
    const [DDFMaximums, setDDFMaximums] = React.useState([])
    const [showPopup, setShowPopup] = React.useState(null)
    const [usefulness, setUsefulness] = React.useState([])
    const [images, setImages] = React.useState([])
    const [imageIndex, setImageIndex] = React.useState([])
    const [diseaseDescription, setDiseaseDescription] = React.useState([])
    const [findingSelected, setFindingSelected] = React.useState("")
    const [onChange, setOnChange] = React.useState(false)
    const [onCreateDF, setOnCreateDF] = React.useState(false)
    const [photos, setPhotos] = React.useState([])
    const [findingImages, setFindingImages] = React.useState(false);
    const [updated, setUpdated] = React.useState(false)


    const theme = useTheme();


  



    React.useEffect(() => {
        const getAllNames = async () =>  {
    
          const findingNames = await axios.get(`https://api.clinicalphenomics.org/finding/names/0`)
          if(findingNames != "No disease exists") {
          setFindings(findingNames.data);
          }
          }
          getAllNames()

          for(var json_obj of filtered) {
            console.log(json_obj)
            if(json_obj.type == "finding") {
            const set = filteredSet.add(json_obj.id)
            setFilteredSet(set)
            }
          }

    }, [])



    React.useEffect(() => {



      const getDFindings = async() => {
        if(findings.length == 0 || diseases.length == 0 ){
          return;
        }



      const finding_ids = findings.map((finding) => finding.id)
      const disease_ids = diseases.map((disease) => disease.id)




      const payload = {
        disease_ids: disease_ids,
        finding_ids: finding_ids
      }
      //Call the endpoint



      const dFindings = await axios.post(`https://api.clinicalphenomics.org/diseaseFinding/getDFArray`, payload)

      console.log(dFindings.data)





      setDiseaseFindings(dFindings.data)

      }

      getDFindings();

    }, [findings, diseases, onCreateDF, updated])

    React.useEffect(() => {
      const getMaximums = async () => {


        const disease_ids = diseases.map((disease) => disease.id)
        const finding_ids = findings.map((finding) => finding.id)

        const payload = {disease_ids, finding_ids}

        const maximums = await axios.post('https://api.clinicalphenomics.org/diseaseDiseaseFinding/getMaximums', payload)


        setDDFMaximums(maximums.data)

      }

      getMaximums();
    }, [findings, diseases, onCreateDF, updated])

    React.useEffect(() => {
      const getImages = async () => {
        var imageArray = [];
        var indicies = [];
        var photos = [];
        for(const disease of diseases) {
          console.log(disease)
          const images = await axios.get(`https://api.clinicalphenomics.org/image/all/disease/id/${disease.id}`)
          imageArray.push(images.data)
          indicies.push(images.data.length != 0 ? 1:0)

          if(images.data.length > 0) {
            const url = await axios.get(`https://api.clinicalphenomics.org/image/getImage/${images.data[0].id}`)
            photos.push(url.data)
            const img = new Image()
            img.src = url.data

          }
          else {
            photos.push("none")
          }
        }




        setImages(imageArray)
        setImageIndex(indicies)
        setPhotos(photos)

      }

      getImages()

    }, [diseases])


    const handleAccordionClick = async (event, id, index, unselect ) => {
        if(unselect) {
            const updatedDiseases = { ...findingsSelected};
            delete updatedDiseases[index]
            setFindingsSelected(updatedDiseases);
          }
    
          if(!unselect) {
           const children = await axios.get(`https://api.clinicalphenomics.org/finding/names/${id}`)
           const diseaseIds = diseases.map((disease) => disease.id)
           const findingIds = children.data.map((finding) => finding.id )

           const payload = {
            disease_ids: diseaseIds,
            finding_ids: findingIds
           }

           const dFinding = await axios.post('https://api.clinicalphenomics.org/diseaseFinding/getDFArray', payload)

           const maximums = await axios.post('https://api.clinicalphenomics.org/diseaseDiseaseFinding/getMaximums', payload)


           setFindingsSelected({
            ...findingsSelected,
          [index]: {children: children.data, dFinding: dFinding.data, maximums: maximums.data}
        })
        }
    }

    const iconSelection = (finding, index)=> {
        if(finding.hasChildren == 0) {
          return  <ExpandLessIcon style={{ visibility: 'hidden' }} />; 
        }
    
        else if(findingsSelected.hasOwnProperty(index)) {
          return (<ExpandLessIcon onClick={(event) => {event.stopPropagation(); handleAccordionClick(event, finding.id, index, true)}} 
          />); 
        }
    
        else {
          return ( <ExpandMoreIcon onClick={(event) => {event.stopPropagation(); handleAccordionClick(event, finding.id, index, false)}} 
          />) 
        }
      }

      const handleSelect = (finding) => {

        const isIdPresent = selected.find((disease) => disease.id === finding.id && disease.type === "finding");
        const filIdPresent = filtered.find((disease) => disease.id === finding.id && disease.type === "finding");
        const isInFilter = filteredSet.has(finding.id)


        if (!isIdPresent) {
          setSelected([...selected, {type:"finding", id: finding.id, name: finding.name, knowledge: finding.knowledge}]);
          }
  
        if (!filIdPresent) {
          setFiltered([...filtered, {type:"finding", id: finding.id, name: finding.name, knowledge: finding.knowledge}]);
        }

        if(!isInFilter) {
          const set = filteredSet.add(finding.id)
          setFilteredSet(set)
        }
      }

      const handleDeselect = (finding) => {
        const updatedArray = selected.filter(item => item.id !== finding.id || item.type != "finding");
        setSelected(updatedArray);
  
        const updatedAllArray = filtered.filter(item => item.id !== finding.id || item.type != "finding");
        setFiltered(updatedAllArray)

        filteredSet.delete(finding.id)

      }

      React.useEffect(() => {
        const updateDisease = async (id) => {
          var dFinding = await axios.get(`https://api.clinicalphenomics.org/diseaseFinding/${id}`)
          setSelectedDFinding(dFinding.data)
        }

        if(isModalOpen && selectedDFinding) {
          updateDisease(selectedDFinding.id)
        }

      }, [onChange])

      const handleCameraClick = async () => {
        setFindingImages(true)

      }

      const style = {
        position: 'absolute',
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: "24vh",
        pt: "2vh",
        px: "4vh",
        pb: "3vh",
        maxHeight: '90vh',
        minHeight: '55vh',
      };


  

      const handleDFindingSelect = async (id, disease_id, finding_id) => {

        
        var dFinding

        if(id == -1) {
          const payload = {
            disease_id: disease_id,
            finding_id: finding_id
          }
          dFinding = await axios.post(`https://api.clinicalphenomics.org/diseaseFinding/`, payload)
          setOnCreateDF(!onCreateDF)
        }

        else {
         dFinding = await axios.get(`https://api.clinicalphenomics.org/diseaseFinding/${id}`)
        }

        setIsModalOpen(true)
        setSelectedDFinding(dFinding.data)
      }

      const handleCircleHover = async (event, finding_id, name) => {
        console.log(finding_id)
        setShowPopup(event.currentTarget)
        console.log(event.currentTarget)
        const disease_ids = diseases.map((disease) => disease.id)
        const payload = {disease_ids, finding_id}
        const useful = await axios.post('https://api.clinicalphenomics.org/diseaseDiseaseFinding/getDDFDFArray', payload)
        console.log(useful.data)
        setUsefulness(useful.data.usefulness)
        console.log(useful.data.usefulness)
        console.log(name)
        setFindingSelected(name)

      }

      const handleCircleLeave = () => {
        setShowPopup(null)
        setFindingSelected("")
      }

      const popupOpen = Boolean(showPopup)

      const labels = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

      const handleImageBackward =  async (index) => {
        var imgIndex = 0;

        const length = images[index].length
        
        if(length == 0) {
          return;
        }

        let updated = [...imageIndex]


        if(updated[index] == 1) {
          updated[index] = length;
          imgIndex = length - 1;

          console.log(images[index][imgIndex])

          setImageIndex(updated)

        }

        else {
          updated[index]--;
          imgIndex = updated[index] - 1;
          setImageIndex(updated)
        }


        const url = await axios.get(`https://api.clinicalphenomics.org/image/getImage/${images[index][imgIndex].id}`)
        const img = new Image();
        img.src = url.data
        let newPhoto = [...photos]
        newPhoto[index] = url.data
        setPhotos(newPhoto)

      }

      const handleImageForward = async (index) => {
        const length = images[index].length
        let imgIndex;
        if(length == 0) {
          return;
        }

        
        let updated = [...imageIndex]

        if(updated[index] == length) {
          updated[index] = 1;
          imgIndex = 0;

        }
        else {
          updated[index]++;
          imgIndex = updated[index] - 1
        }
        setImageIndex(updated) 

        const url = await axios.get(`https://api.clinicalphenomics.org/image/getImage/${images[index][imgIndex].id}`)
        const img = new Image();
        img.src = url.data
        let newPhoto = [...photos]
        newPhoto[index] = url.data
        setPhotos(newPhoto)

      }

      const onCloseImageModal = () => {
        setFindingImages(false)
    }
      const renderFindings =  (findings, parentIndex = null, diseaseFindings, maximums) => {

        console.log(DDFMaximums)


        return findings.map((finding, index) => {
            const currentIndex = parentIndex !== null ? `${parentIndex}-${index}` : `${index}`;
            return (
            <>
            <TableRow style={{ backgroundColor: findingsSelected.hasOwnProperty(currentIndex) ? theme.palette.grey[200] : 'white' }} key={finding.id}>
              <TableCell >
                  <div  style={{display: 'flex', alignItems: 'center'}}> 
              {iconSelection(finding, currentIndex)}


              {!filteredSet.has(finding.id) &&  <Tooltip title="Select finding to view in finding tab"><LockOpen onClick={() => handleSelect(finding)} style={{marginLeft: '10px', marginRight: '10px', marginBottom: '-3px'}}/>  </Tooltip>}
              {filteredSet.has(finding.id) && <Lock onClick={() => handleDeselect(finding)}  style={{marginLeft: '10px', marginRight: '10px', marginBottom: '0px'}}/>}


              {finding.numImages != 0 && <Tooltip title="Select to view images of finding"> <CameraAltIcon  style={{ marginBottom: '-7px',marginLeft: '0px',  marginRight: '10px'}} onClick={() => handleCameraClick()}/> </Tooltip>} 
              {finding.numImages == 0 && <Tooltip title="No images for finding"> <CameraAltIcon color="disabled" style={{ marginBottom: '-7px',marginLeft: '0px',  marginRight: '10px', }}/> </Tooltip>}

              {finding.name}
          {finding.numImages != 0 &&   <Modal
        open={findingImages}
        onClose={onCloseImageModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
          >
        <Box sx={{ ...style, textAlign:'center'}}>
        <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
          <CloseIcon  onClick={onCloseImageModal}/>
          </div>

          <ImageTab type="finding" entity={finding} edit={false}/> 


          </Box>
          </Modal> }
 
              
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>


              <CircleIcon
              onMouseEnter={(event) => handleCircleHover(event, finding.id, finding.name)}
              onMouseLeave={handleCircleLeave}
              style={{ color: "red", marginLeft: '10px' }}
              />

         

                  <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',

              '& .MuiPaper-root': {
                border: '2px solid', // Remove the border
                borderColor: 'black',
                boxShadow: 'none', // Remove the shadow
                maxWidth: '80vw',
              },
            }}
            open={popupOpen}
            anchorEl={showPopup}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleCircleLeave}
            disableRestoreFocus
          >
          <div style={{ padding: '20px', textAlign: 'center' }}>
    
            <h2>  Compare Usefulness </h2>

            {console.log(findingSelected)}


            <p> The matrix below shows how helpful <strong>{findingSelected}  </strong> is for  <br />  differentiating between
            the diseases in the  horizontal  <br /> and vertical columns.  White indicates that no data was entered, <br />
            while a stronger shade of red indicates a stronger distinction could be made.</p>
            <p>  <strong> Finding: </strong> {findingSelected} </p>

            <Matrix usefulness={usefulness} />

            {diseases.map(((disease, index) => (
                <p>  <strong> {labels[index]}: </strong> {disease.name} </p>
            )))}




  

          </div>



    
          </Popover>

                  <Box
               sx={{
                  top: 0,
                  left: 10,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
              <Typography               onMouseEnter={(event) => handleCircleHover(event, finding.id, finding.name)}
              onMouseLeave={handleCircleLeave} style={{color: "white"}} >
                    {maximums[index]}
              </Typography>
              </Box>        


      
              </Box>
              </div>
              </TableCell>

              {diseaseFindings.length != 0 && diseaseFindings[index].map((dFinding, ind) => (


                

<TableCell   style={{
    cursor: 'pointer',
    color: 'blue',      
  }}
 onClick={() => {
  console.log(index);
  console.log(diseaseFindings[index]);
  handleDFindingSelect(dFinding.id, diseases[ind].id, finding.id )}} key={diseases.length * ind + index} >

  {dFinding.prevalence}  {!isNaN(parseFloat(dFinding.prevalence)) && "%"}

</TableCell>


                
             ))}


            </TableRow>


           {findingsSelected.hasOwnProperty(currentIndex) && renderFindings(findingsSelected[currentIndex].children, currentIndex, findingsSelected[currentIndex].dFinding, findingsSelected[currentIndex].maximums)}
           </>
        )})

      }

    const height = window.screen.height *211/12000+ 72.21

    return ( 

        <div  style={{}} >
        <NavBar />

    <div style={{ }}>
        
      <TableContainer  component={Paper} style={{ height: height + "vh", margin: "0", padding: "0"}}>
        <Table style={{minWidth: 800 }}>
          <TableHead >
            <TableRow >
              <TableCell style={{ minWidth: "300px"}} ></TableCell>
              {diseases.map((disease, index) => (

                <TableCell style={{ minWidth: "400px", alignItems: 'left'}} key={disease.id}>
                    
                   {disease.name}
                    
                {photos[index]  && <Box sx={{bgcolor: 'background.paper', borderColor: 'text.primary', width: `400px`, height: `400px`, marginTop: '10px', border: 1 }}>
                  <img src={photos[index]} alt="Image" style={{ width: '100%', height: '100%' }} />
                </Box> }

                <Box sx={{width: '300px', marginTop: '10px', flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}> 
                <ArrowBackIosNewIcon onClick={() => handleImageBackward(index)} style={{ fontSize: '18px', cursor: 'pointer' }} />
                {console.log(imageIndex[index])}
                <span style={{ fontSize: '18px', margin: '0 10px' }}>{`${imageIndex[index]}/${images[index] != null ? images[index].length: 0}`}</span>
                <ArrowForwardIosIcon onClick={() => handleImageForward(index)} style={{ fontSize: '18px', cursor: 'pointer' }} />



                </Box>
                </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody >
            {renderFindings(findings, null, diseaseFindings, DDFMaximums)}
          </TableBody>
        </Table>
      </TableContainer>

      {isModalOpen && <DiseaseFindingModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} dFinding={selectedDFinding} otherDiseases={diseases.filter((disease) => disease.id != selectedDFinding.diseaseId)} onChange={onChange} setOnChange={setOnChange} updatedNew={updated} setUpdated={setUpdated}/>}


    </div>



    </div>
  );
}

export default DiseasePage;