import { Grid, Box, Icon} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';






function EditTabs({activeTab, onTabChange, type}) {

    const handleChange = (event, newValue) => {
      onTabChange(newValue);
    };
      
    return (
      <Box sx={{ width: '100%' }}>

        {type.toLowerCase() == 'disease' ? 
        <Tabs
          value={activeTab}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >

          <Tab value="tab1" label="Info" />
          <Tab value="tab2" label="Relations" />
          <Tab value="tab3" label="Images" />
          <Tab value="tab4" label="Prevalence"/>
          <Tab value="tab5" label="Incidence" /> 
          <Tab value="tab6" label="Knowledge" />
        </Tabs>
        :         <Tabs
        value={activeTab}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >

        <Tab value="tab1" label="Info" />
        <Tab value="tab2" label="Relations" />
        <Tab value="tab3" label="Images" />
        <Tab value="tab6" label="Knowledge" />
      </Tabs>}
      </Box>
    );
  }

  export default EditTabs;
