import { Grid, Box, Icon} from '@mui/material';
import ChipModal from "../ChipModal/ChipModal"

function BoxSx({allSelected, setAllSelected, selected, setSelected, onUpdate, setOnUpdate}) {

    const commonStyles = {
      bgcolor: 'background.paper',
      borderColor: 'text.primary',
      width: '20vw',
      height: '40vh',
      overflowY: 'auto'
    };
  
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ ...commonStyles, border: 1 }}>
  
            <ChipModal allSelected={allSelected} setAllSelected={setAllSelected} selected={selected} setSelected={setSelected} onUpdate={onUpdate} setOnUpdate={setOnUpdate}/>
          </Box>
        </Box>
      );
      }  
export default BoxSx;