import React from 'react';
import Chip from '@mui/material/Chip';
import { v4 as uuidv4 } from 'uuid';
import CancelIcon from '@mui/icons-material/Cancel';
import EditDiseaseModal from "../EditDiseaseModal/EditDiseaseModal"


function ChipModal({allSelected, setAllSelected, selected, setSelected, onUpdate, setOnUpdate}) {


    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [entityId, setEntityId] = React.useState(-1);
    const [type, setType] = React.useState("")
  
  
  
    function handleChipClick(event, id, type) {
      setEntityId(id)
      setIsModalOpen(true)
      setType(type)

    }
  
    function handleDelete(event, id, type) {


      if(allSelected) {

      const updatedAllArray = allSelected.filter(item => item.id !== id || item.type != type);
      setAllSelected(updatedAllArray)
      }


      const updatedArray = selected.filter(item => item.id !== id || item.type != type);
      setSelected(updatedArray);


  
    }

    const primaryEntities = ["finding", "disease children", "finding children"];

    return ( <div>
  
      {selected.map((disease) => (
          <Chip sx={{marginTop: '10px', width: '100%' , justifyContent: 'space-between'}} color={primaryEntities.includes(disease.type) ? "primary":"default"} onDelete={(event) => handleDelete(event, disease.id, disease.type)}  deleteIcon={<CancelIcon />} key={uuidv4()} label={disease.name} onClick={(event) => handleChipClick(event, disease.id, disease.type)} />
      ))}
    
  
    {isModalOpen && <EditDiseaseModal  entityId={entityId} isModalOpen={isModalOpen} type={type} onUpdate={onUpdate} setOnUpdate={setOnUpdate} onClose={() => setIsModalOpen(false)} deleteAction={handleDelete} allSelected={allSelected} setAllSelected={setAllSelected} selected={selected} setSelected={setSelected}/>}
  
    </div>
  
  
  )
  }

  export default ChipModal;