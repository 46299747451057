import React from 'react';
import axios from "axios";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { ThumbUp, Flag, Reply, ModeEdit, ArrowBackIosNew } from '@mui/icons-material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import { Grid, Box, Icon} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import CommentIcon from '@mui/icons-material/Comment';
import { Container, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';


import CommentsEditTab from './CommentsEditTab/CommentsEditTab'
import ImagesEditTab from './ImagesEditTab/ImagesEditTab'
import IncidenceEditTab from './IncidenceEditTab/IncidenceEditTab'
import RelationsEditTab from './RelationsEditTab/RelationsEditTab'
import PrevalenceEditTab from './PrevalenceEditTab/PrevalenceEditTab'
import KnowledgeEditTab from './KnowledgeEditTab/KnowledgeEditTab'
import EditTabs from './EditTabs/EditTabs'
import AddFlagModal from '../../../../components/AddFlagModal/AddFlagModal';
import CommentModal from '../../../../components/CommentModal/CommentModal';
import SeeFlagModal from '../../../../components/SeeFlagModal/SeeFlagModal';

function EditDiseaseModal({isModalOpen, onClose, entityId, type, onUpdate, setOnUpdate, deleteAction, allSelected, setAllSelected, selected,setSelected}) {

    const [entity, setEntity] = React.useState({})
    const [titleEditMode, setTitleEditMode] = React.useState(false)
    const [descripEditMode, setDescripEditMode] = React.useState(false)
    const [numRatings, setNumRatings] = React.useState(0);


    const [currUser, setCurrUser] = React.useState({})
    const [onEntityUpdated, setOnEntityUpdated] = React.useState(false)
    const [seeFlagOpen, setSeeFlagOpen] = React.useState("");

    React.useEffect(() =>{
      const fetchEntities = async () => {
  
        const newEntity = await axios.get(`https://api.clinicalphenomics.org/raiting/DorF/stats/${type}/id/${entityId}`)
        setNumRatings(newEntity.data)
      }
  
      fetchEntities()
  
    }, [entity, onEntityUpdated])

    React.useEffect(() => {
  
  
      const fetchEntity = async() => {
        
        if(document.cookie == "") {
          return;
        }
        
        const user = await axios.get(`https://api.clinicalphenomics.org/currUserInfo`,
        {
          headers: {
            Authorization: `Bearer ${document.cookie}`,
          },
       })

       setCurrUser(user.data)


  
      }

      fetchEntity()
  
  
    }, [])
  
    React.useEffect(() => {

  
      const fetchEntity = async () => {

        const newEntity = await axios.get(`https://api.clinicalphenomics.org/${type}/id/${entityId}`)

  

      console.log(newEntity.data)

      setEntity(newEntity.data);
      setUpdatedTitle(newEntity.data.name);
      setUpdatedDescp(newEntity.data.description);

  
  
  
      }
  
      fetchEntity(entityId)
  
     
  
    }, [entityId, titleEditMode, descripEditMode, onEntityUpdated])
      
    const style = {
      position: 'absolute',
      top: '50vh',
      left: '50vw',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: "24vh",
      pt: "2vh",
      px: "4vh",
      pb: "3vh",
      maxHeight: '90vh',
      overflowY: 'auto',
    };
  
    function createData(name, statistic) {
      return { name, statistic};
    }
    

    var rows; 
    if(type == "disease") {
     rows = [
      createData('Average Prevalence', `${parseInt(1000 * entity.prevalence)}/100000`),
      createData('Number of Prevalence References', entity.numRefPrevalence),
      createData('Average Incidence', `${parseInt(1000 * entity.incidence)}/100000`),
      createData('Number of Incidence References', entity.numRefIncidence),
      createData('Average Knowledge', numRatings != 0 ?  entity.knowledge: ""),
    ];
  } else {
    rows = [
      createData('Average Knowledge', numRatings != 0 ?  entity.knowledge: ""),
    ]
  }
  
    
  
    const [active, setActive] = React.useState("tab1")
    const [page, setPage] = React.useState(0)
    const [updatedTitle, setUpdatedTitle] = React.useState("")
    const [updatedDescp, setUpdatedDescp] = React.useState("")


  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleDelete = async () => {
      await axios.delete(`https://api.clinicalphenomics.org/${type}/${entityId}`, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })

      if(allSelected) {

        const updatedAllArray = allSelected.filter(item => item.id !== entityId || item.type != type);
        setAllSelected(updatedAllArray)
        }
  
  
        const updatedArray = selected.filter(item => item.id !== entityId || item.type != type);
        setSelected(updatedArray);

      setOnUpdate(!onUpdate)
      onClose()

    }



    const switchToTitleEdit = () => {
      setTitleEditMode(true)
    }

    const updateTitle = (event) => {
      setUpdatedTitle(event.target.value)
    }

    const switchToDescriptionEdit = () => {
      setDescripEditMode(true)
    }

    const updateDescription = (event) => {
      setUpdatedDescp(event.target.value)
    }


    const updateDiseaseInfo = async () => {

      if(updatedTitle == "") {
        alert("Title cannot be blank for diseae or finidng");
        return
      }

      if(updatedDescp == "") {
        alert("Description cannot be blank for disease or finding")
        return
      }

      var payload = {};

      if(updatedTitle != entity.name) {
        payload["name"] = updatedTitle
        setUpdatedTitle(entity.name)
      }

      if(updatedDescp != entity.description) {
        payload["description"] = updatedDescp;
        setUpdatedDescp(entity.description)
      }


      await axios.put(`https://api.clinicalphenomics.org/${type}/id/${entityId}`, payload,
      {
        headers: {
          Authorization: `Bearer ${document.cookie}`,
        },
      })

      setTitleEditMode(false);
      setDescripEditMode(false);








    }

  
    const renderContent  = () => {
  
      if(active == "tab1") {
        return (
          <div>





          <div style={{ display: 'flex', alignItems: 'center' }}>



          <CommentModal type={type} id={entity.id}/>



    <div style={{ flexGrow: 0.1 }}></div>


    {!titleEditMode &&  
    <h1 style={{ flexGrow: 1}}> {entity.name}
      {currUser.id == entity.UserId && 
      <ModeEditIcon color="primary" onClick={switchToTitleEdit} style={{marginLeft: "10px"}}  /> }
    </h1>
    }

   {titleEditMode &&  
   <div style={{marginTop: '25px'}}> 
   <TextField value={updatedTitle} onChange={updateTitle} multiline inputProps={{paddingTop: 0, style: {  minHeight: `40px`, maxHeight: `30px`, minWidth: `575px`, maxWidth: `575px`, textAlign: 'left'} }} variant="outlined"/> 
   <Button  variant="contained" onClick={updateDiseaseInfo} style={{marginTop: '10px'}}> Update </Button>
   </div> 


    }





    <AddFlagModal imageType="plus" type = {(type == "disease") ? "Disease": "Finding"} entity={entity} />
    <Tooltip title="Question">
    <Flag onClick={() => {setSeeFlagOpen("Question")}} style={{ color: 'red' }}  />
    </Tooltip>
    {seeFlagOpen =="Question" && <SeeFlagModal type = {(type == "disease") ? "Disease": "Finding"} id={entity.id} flag_type="Question" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
    <span> {entity.numQFlags}</span>
    <Tooltip title="Comment">
    <Flag onClick={() => {setSeeFlagOpen("Comment")}} style={{ color: 'orange' }}  />
    </Tooltip> 
    {seeFlagOpen =="Comment" && <SeeFlagModal type = {(type == "disease") ? "Disease": "Finding"} id={entity.id} flag_type="Comment" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
    <span> {entity.numCFlags} </span>
    <Tooltip title="Verify">
    <Flag onClick={() => {setSeeFlagOpen("Verify")}} style={{ color: 'green' }}  />
    </Tooltip>
    {seeFlagOpen =="Verify" && <SeeFlagModal type = {(type == "disease") ? "Disease": "Finding"} id={entity.id} flag_type="Verify" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
    <span> {entity.numVFlags} </span>
    <Tooltip title="Review">
    <Flag onClick={() => {setSeeFlagOpen("Review")}}  style={{ color: 'blue' }}  />
    </Tooltip>
    {seeFlagOpen =="Review" && <SeeFlagModal type = {(type == "disease") ? "Disease": "Finding"} id={entity.id} flag_type="Review" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
    <span> {entity.numRFlags} </span>
  </div>
  <div style={{ textAlign: 'left' }}>
    {!descripEditMode && 
    <div> 
    <h3>Description:
    {currUser.id == entity.UserId && 

    <ModeEditIcon onClick={switchToDescriptionEdit} fontSize="small" color="primary" style={{marginLeft: "10px"}} />
      }
    </h3>
    <p>{entity.description}</p>
    </div>
      }

    {descripEditMode && 

    <div>

    <h3> Description: </h3>

    
    <div> 
   <TextField value={updatedDescp} onChange={updateDescription} multiline inputProps={{paddingTop: 0, style: {  minHeight: `40px`, maxHeight: `30px`, minWidth: `575px`, maxWidth: `575px`, textAlign: 'left'} }} variant="outlined"/> 
    </div>

   <Button  variant="contained" onClick={updateDiseaseInfo} style={{marginTop: '10px'}}> Update </Button>
   </div>
    
    
    }
      
    <h3>Statistics:</h3>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
       <TableBody>
       {rows.map((row) => (
          <TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.statistic}</TableCell>
  
          </TableRow>
        ))}
  
       </TableBody>
  
       </Table>
        </TableContainer>

        <h3> Additional Info</h3>

        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
       <TableBody>

       <TableRow
            key={entity.first_name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
             Created By
            </TableCell>
            {entity.User != null && <TableCell align="right"> {entity.User.first_name} {entity.User.last_name} </TableCell>}
  
          </TableRow>

       </TableBody>
  
      </Table>
      </TableContainer>

      {currUser.id == entity.UserId &&      <Button   style={{marginRight: "30px", marginTop: "10px", justifyContent: 'right'}} onClick={handleDelete} color="error" variant="contained" > Delete </Button>}




  
      
        </div>
        </div>
        )
        }
  
        else if(active == "tab2") {
          return (<RelationsEditTab user={currUser} type={type} entity={entity}/>)
        }
        
        else if(active == "tab3") {
          return (<ImagesEditTab user={currUser} type={type} entity={entity}  />)
        }
  
        else if(active == "tab4") {
          return (<PrevalenceEditTab  entity={entity} updatedNew={onEntityUpdated} setUpdatedNew={setOnEntityUpdated}/>)
        }
  
        else if(active == "tab5") {
          return (<IncidenceEditTab entity={entity} updatedNew={onEntityUpdated} setUpdatedNew={setOnEntityUpdated}/>)
        }
  
        else if(active == "tab6") {
          return (<KnowledgeEditTab type={type} entity={entity} updated={onEntityUpdated} setUpdated={setOnEntityUpdated}/>)
        }
  
        else if(active == "tab7") {
          return (<CommentsEditTab  />)
        }
  
        }
  
  
  
    return (
      <div>
        <Modal
          open={isModalOpen}
          onClose={onClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, overflowY: 'auto', textAlign:'center'}}>
          <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
  
            <CloseIcon  onClick={onClose}/>
  
            </div>
   
            <EditTabs activeTab={active} onTabChange={setActive} type={type} />
  
            {renderContent()}
  
            </Box>
            </Modal>
            </div>
            
  
  
    );
  }

  export default EditDiseaseModal;