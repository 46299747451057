import { TextField } from "@mui/material"
import { Grid, Box, Icon} from '@mui/material';
import React from "react";
import { styled, useTheme, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import axios from "axios";
function RatingDFModal({dFinding, otherDiseases, updated, setUpdated}) {

    const [diseaseSelected, setDiseaseSelect] = React.useState(-1) 
    const [DDFStats, setDDFStats] = React.useState({})
    const [userKnowledgeRating, setUserKnowledgeRating] = React.useState({});
    const [valueKnowledgeEntered, setValueKnowledgeEntered] = React.useState(-1);
    const [userCompareRating, setUserCompareRating] = React.useState({});
    const [valueCompareRating, setValueCompareRating] = React.useState({});
    const validValues = ["1", "2", "3", "4", "5", "6", "7", "8", "9"]

    const commonStyles = {
        bgcolor: 'background.paper',
        borderColor: 'text.primary',
        width: '20vw',
        height: '40vh',
        overflowY: 'auto'
      };

      function createData(name, statistic) {
        return { name, statistic};
      }

      React.useEffect(() => {
        const getDDFStats = async () => {
          console.log(dFinding)
         const dFindingId = dFinding.id
         const otherDiseasesIds = otherDiseases.map((disease) => disease.id)
         const payload = {
          dFinding_id: dFindingId,
          disease_ids: otherDiseasesIds
         }

         const stats = await axios.post("https://api.clinicalphenomics.org/diseaseDiseaseFinding/getDDFStats", payload);

         const map = {}
         for(let x = 0; x < otherDiseasesIds.length; x++) {
          map[otherDiseasesIds[x]] = stats.data[x]
         }

         setDDFStats(map)


        }

        getDDFStats()
      }, [updated])

      React.useEffect(() => {
        const payload = {
          type:"DiseaseFinding",
          type_id: dFinding.id
        }
    
         const getUserRating = async () => {
          if(document.cookie != "") {
            const userRating = await axios.post('https://api.clinicalphenomics.org/raiting/DandF/getByUser', payload,  {
              headers: {
                Authorization: `Bearer ${document.cookie}`
              }
            })
    
            let currUserRating = userRating.data.length > 0 ? userRating.data[0] : {}
            setUserKnowledgeRating(currUserRating)
            setValueKnowledgeEntered(currUserRating.knowledge ? currUserRating.knowledge : -1)
            }
          else{
            return;
          }
         }
    
        getUserRating()
        }, [updated])

        React.useEffect( () => {

          const getDDFStats = async () => {
          if( Object.keys(DDFStats) == 0) {
            return
          }
          if(document.cookie != "") {
          let currRating = {}
          let currValue = {}
          for(var disease of otherDiseases) {


            var currUserRating = {}
            var valueEntered = -1;

          //Case when does not have id, no DiseaseDiseaseFinding object was created, which
          //means that no rating exists. So should be left blank and have a negative one value
          console.log(DDFStats)


          if(DDFStats[disease.id].hasOwnProperty('id')) {
            const payload = {
              type: "DiseaseDiseaseFinding",
              type_id: DDFStats[disease.id].id
            }

            const userRating = await axios.post('https://api.clinicalphenomics.org/raiting/DandF/getByUser', payload,  {
              headers: {
                Authorization: `Bearer ${document.cookie}`
              }
            })

            currUserRating = userRating.data.length > 0 ? userRating.data[0] : {}
            valueEntered = currUserRating.usefulness ? currUserRating.usefulness : -1;

          }
          
          currRating[disease.id] = currUserRating
          currValue[disease.id] = valueEntered

        }

        setUserCompareRating(currRating)
        setValueCompareRating(currValue)

        } else {
          return;
        }
          }

          getDDFStats()
        }, [DDFStats, updated])

        const handleRateUseful = async () => {
          console.log("UPDATED UPDATEED")
          const userUsefulRating = userCompareRating[diseaseSelected.id]
          const valueUsefulEntered = valueCompareRating[diseaseSelected.id]

          console.log(userCompareRating)

            if(Object.keys(userUsefulRating) == 0) {
              if(valueUsefulEntered == -1) {
                return
              }
              else {
                if(!validValues.includes(String(valueUsefulEntered))) {
                  alert("Your rating must be an integer from 1 to 9")
                  return
                }
                const newDiseaseFinding = DDFStats[diseaseSelected.id]
                var ddFindingId;
                console.log(DDFStats)
                if(!newDiseaseFinding.hasOwnProperty("id")) {
                  const ddFPayload = {df_id: dFinding.id, disease_id: diseaseSelected.id}
                  const newddFinding =  await axios.post("https://api.clinicalphenomics.org/diseaseDiseaseFinding/", ddFPayload, {
                    headers: {
                      Authorization: `Bearer ${document.cookie}`
                    }
                  })

                  

                  ddFindingId = (newddFinding.data)[0].id
                  console.log(newddFinding)


                } else {
                  ddFindingId = newDiseaseFinding.id
                }

                var newType = "DiseaseDiseaseFinding"
                const payload = {type: newType, usefulness: valueUsefulEntered, type_id: ddFindingId}
                const newRating = await axios.post("https://api.clinicalphenomics.org/raiting/DandF", payload, {
                  headers: {
                    Authorization: `Bearer ${document.cookie}`
                  }
                })
              }
            }
            else {

              if(valueUsefulEntered == -1) {

                const deleteDDFinding = (DDFStats[diseaseSelected.id].DDFRatingCount == -1)

                console.log(userUsefulRating)
                await axios.delete(`https://api.clinicalphenomics.org/raiting/DandF/${userUsefulRating.id}`, {
                  headers: {
                    Authorization: `Bearer ${document.cookie}`
                  }
                })

                if(deleteDDFinding) {
                  await axios.delete(`https://api.clinicalphenomics.org/diseaseDiseaseFinding/${DDFStats[diseaseSelected.id].id}`, {
                    headers: {
                      Authorization: `Bearer ${document.cookie}`
                    }
                  })
                }
              }
              

              else {

                console.log("UPDATING AGAIN")

                if(!validValues.includes(String(valueUsefulEntered))) {
                  alert("Your rating must be an integer from 1 to 9")
                  return
                }

                const payload = {
                  usefulness: valueUsefulEntered
                }

                await axios.put(`https://api.clinicalphenomics.org/raiting/type/DandF/${userUsefulRating.id}`, payload, {
                  headers: {
                    Authorization: `Bearer ${document.cookie}`
                  }
                })
              }

          }

          setUpdated(!updated)

        }

        const handleRateKnowledge = async () => {
          if(Object.keys(userKnowledgeRating) == 0) {
            if(valueKnowledgeEntered == -1) {
              return
            }
            else {
              if(!validValues.includes(String(valueKnowledgeEntered))) {
                alert("Your rating must be an integer from 1 to 9")
                return
              }
      
              var newType = "DiseaseFinding"
      
              const payload = {type: newType, knowledge: valueKnowledgeEntered, type_id: dFinding.id }
              const result = await axios.post("https://api.clinicalphenomics.org/raiting/DandF", payload, {
                headers: {
                  Authorization: `Bearer ${document.cookie}`
                }
              })
      
              console.log(result)
            }
          }
          else {
            if(valueKnowledgeEntered == -1) {
              const a = await axios.delete(`https://api.clinicalphenomics.org/raiting/DandF/${userKnowledgeRating.id}`, {
                headers: {
                  Authorization: `Bearer ${document.cookie}`
                }
              })
            }
            else {
              if(!validValues.includes(String(valueKnowledgeEntered))) {
                alert("Your rating must be an integer from 1 to 9")
                return
              }

              const payload = {
                knowledge: valueKnowledgeEntered
              }
            
              await axios.put(`https://api.clinicalphenomics.org/raiting/type/DandF/${userKnowledgeRating.id}`, payload, {
                headers: {
                  Authorization: `Bearer ${document.cookie}`
                }
              })
            }
          }
          
          setUpdated(!updated)

         }
      
      

        const handleKnowledgeChange = (event) => {
          if(event.target.value == "") {
            setValueKnowledgeEntered(-1)
          } else {
          setValueKnowledgeEntered(event.target.value)
          }
         }

      var rows = [
        createData("Average Knowledge", dFinding.knowledge),
        createData("Number of Ratings", dFinding.numberKnowledge)
      ]

      

      console.log(DDFStats)


      var usefulRows = (Object.keys(DDFStats).length > 0 && diseaseSelected != -1) ? [
        createData("Average usefulness", DDFStats[diseaseSelected.id].usefulness),
        createData("Number of Ratings", DDFStats[diseaseSelected.id].DDFRatingCount)
      ]: []

      React.useEffect(() => {
        if(otherDiseases.length != 0) {
            setDiseaseSelect({id: otherDiseases[0].id, name: otherDiseases[0].name})
        }
      }, [updated])

      const theme = useTheme();

      const accordionStyle = (key) => ({
        width: '20vw',
        backgroundColor: diseaseSelected.id==key ? theme.palette.grey[200] : 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      });

      const handleAccordionClick = (event, id, name) => {
        setDiseaseSelect({id, name})
      }

      const handleUsefulnessChange = (event) => {
        const newArr = {...valueCompareRating}
        if(event.target.value == "") {
          newArr[diseaseSelected.id] = -1;
        }
        else {
          newArr[diseaseSelected.id] = event.target.value;
        }


        setValueCompareRating(newArr)

      }


    return (
        <div> 

        <h1> {dFinding.disease_name} and {dFinding.finding_name} </h1>

        <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
      <Table sx={{ minWidth: 650,}} aria-label="simple table">
       <TableBody>
       {rows.map((row) => (
          <TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.statistic}</TableCell>
  
          </TableRow>
        ))}
  
       </TableBody>
  
       </Table>
        </TableContainer>

        {document.cookie == "" && <p> You need to log in to add a knowledge rating.</p>}
        {document.cookie != "" && 
        <>
        <div style={{ display: "flex", alignItems: "center" }}>

    
        <p> Please specify to what extent knowledge of <strong>{dFinding.finding_name}  </strong> in <strong>{dFinding.disease_name}  </strong>is
          considered basic or advanced on a scale of 1 to 9 with 9 representing expert subspecialty knowledge
        and 1 representing cursory medical knowledge. </p>


        
        <TextField  value={valueKnowledgeEntered != -1 ? valueKnowledgeEntered: ""} onChange = {handleKnowledgeChange} style={{marginLeft: "10px"}} > </TextField>

        </div>

        <Button variant="contained" onClick={handleRateKnowledge} style={{marginLeft: '10px'}} > Submit </Button>
       </>
       }

        <Divider style={{marginTop: '20px', marginBottom: '20px'}} />


        {otherDiseases.length != 0 && 

        <div style={{ display: "flex", alignItems: "center" }}>




        <Box sx={{ display: 'flex', marginTop: '10px', marginRight: '20px', justifyContent: 'center' }}>
          <Box sx={{ ...commonStyles, border: 1 }}>

            {otherDiseases.map((disease) => (

          <Accordion 
      style={accordionStyle(disease.id)} 
      expanded={false}
      value={disease.name}
      onChange={(event) => handleAccordionClick(event, disease.id, disease.name)}
      > 
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: '0 1 100%',
          }}
          >          

  
  <div style={{ flex: 1, flexWrap: 'wrap' }}>
  
          <Typography style={{
            whiteSpace: 'normal',
            wordBreak: 'break-all'
          }}>{disease.name}</Typography>
          </div>
  
      </AccordionSummary>
     </Accordion>

   ))}
    


  
          </Box>
        </Box>

        <div> 

        <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
      <Table sx={{ minWidth: 450,}} aria-label="simple table">
       <TableBody>
       {usefulRows.map((row) => (
          <TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.statistic}</TableCell>
  
          </TableRow>
        ))}
  
       </TableBody>
  
       </Table>
        </TableContainer>

        {document.cookie == "" && <p> You need to log in to add a rating to compare two diseases with a finding.</p>}
        {document.cookie != "" && 
        <>

        <p> Please enter a number from 1 to 9 to compare the usefulness of <strong> {dFinding.finding_name} </strong> for differentiating  <strong> {dFinding.disease_name} </strong>
        with <strong>{diseaseSelected.name}  </strong>. 1 is considered LEAST useful while 9 is considered MOST useful.
        Use the select bar on the left to toggle with other diseases selected.</p>


        {valueCompareRating[diseaseSelected.id] && <TextField value={valueCompareRating[diseaseSelected.id] != -1 ? valueCompareRating[diseaseSelected.id] : "" }  onChange={handleUsefulnessChange}/>}

        <div>
        <Button variant="contained" onClick={handleRateUseful} style={{marginTop: '10px'}} > Submit </Button>
        </div>
        </>}

        </div>


    
         </div>
  }
        </div>
    )
}

export default RatingDFModal
